import React, { Component } from "react";

class Error404 extends Component {
  state = {};
  render() {
    return (
      <div>
        <h2>Error 404</h2>
      </div>
    );
  }
}

export default Error404;
