import React from "react";
import { Grid, Image, Header } from "semantic-ui-react";
/* cSpell:disable */

const whirlpool_fridge_exploded = "images/SandBox/Appliances/whirlpool_fridge_exploded.jpg";
const whirlpool_header = "images/SandBox/Appliances/whirlpool_header.jpg";
const whirlpool_ideation_1 = "images/SandBox/Appliances/whirlpool_ideation_1.jpg";
const whirlpool_ideation_2 = "images/SandBox/Appliances/whirlpool_ideation_2.jpg";
const whirlpool_ideation_3 = "images/SandBox/Appliances/whirlpool_ideation_3.jpg";

export default function WhirlpoolFridge() {
  return (
    <>
      <Grid>
        <Grid.Row>
        <Grid.Column width={8}>
            <Image src={whirlpool_header} rounded />
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src={whirlpool_fridge_exploded} rounded />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "4%", marginTop: "1em" }}>
          <Grid.Column width={8}>
            <Header as="h3">
            An on-the-go and emotionally appealing fridge for early-career Indians who are achieving financial stability 
            </Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h4">My Role</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              3D Modeling, Market Research <br />
              Classroom Project with Whirlpool Corporation
            </p>

            <Header as="h4">Duration</Header>
            <p style={{ margin: "-2% 0px 2%" }}>July 2016 (2 weeks)</p>

            <Header as="h4">Advisors</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              Suresh Sethi (formerly Whirlpool, currently UIUC)
              <br />
              Sumit Singh (Whirlpool Corporation)
            </p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4} style={{ marginBottom: "0.2em" }}>
            <Image src={whirlpool_ideation_1} />
          </Grid.Column>
          <Grid.Column width={4}>
            <Image src={whirlpool_ideation_3} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src={whirlpool_ideation_2} />
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}

        <Grid.Row style={{marginBottom: "8%"}}>
          <Grid.Column width={12}>
          <Grid.Column width={12}>
            <p>
            The target audience is people who are attaining financial
              stability and embody a deep rooted Indian-ness blended with a
              global appeal. They live alone, in shared spaces, or with a family
              in privately-owned spaces. The fridge is designed to be modular and 
              stackable, such that when single fridge owners start living together, 
              they can make a bigger fridge.
            </p>
            <br />
            <p>
            The fridge can be mounted on the wall or placed on a counter top.
              The topmost tray has bowl inserts since the Indian cuisine comprises of multiple condiments.
              The partition wall and freezer unit can
              be removed for storing a large vessel, water bottles, etc.
            </p>
          </Grid.Column>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
