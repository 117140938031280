import { Grid, Image, Header, Container, Embed } from "semantic-ui-react";

/* cSpell:disable */

import HeroImage from "../../components/HeroImage";
import HorizontalRule from "../../components/HorizontalRule";

const sendriya_header_1 = "images/Sanitation/sendriya_header_1.png";
const sendriya_materials = "images/Sanitation/sendriya_materials.png";
const sendriya_storyboard = "images/Sanitation/sendriya_storyboard.png";

const uree_header_1 = "images/Sanitation/uree_header_1.jpg";
const uree_ideation_1 = "images/Sanitation/uree_ideation_1.jpg";
const uree_proto_1 = "images/Sanitation/uree_proto_1.jpg";
const uree_proto_2 = "images/Sanitation/uree_proto_2.jpg";
const uree_proto_3 = "images/Sanitation/uree_proto_3.jpeg";
const uree_proto_4 = "images/Sanitation/uree_proto_4.jpg";

const dustbin_header_1 = "images/Sanitation/dustbin_header_1.jpg";
const dustbin_header_2 = "images/Sanitation/dustbin_header_2.png";
const dustbin_ideation_1 = "images/Sanitation/dustbin_ideation_1.jpg";
const dustbin_ideation_2 = "images/Sanitation/dustbin_ideation_2.jpg";
const dustbin_diagram = "images/Sanitation/dustbin_diagram.png";

// ________________________________________________________________________________

export default function Sanitation() {
  return (
    <Container>
      <Grid stackable columns>
        <Grid.Row centered>
          <Header as="h2">
            I. A Composting Toilet which converts excreta to manure
          </Header>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column>
            <Image src={sendriya_header_1} />
          </Grid.Column>
        </Grid.Row>

        {/* Intro and Role */}
        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={8}>
            <div style={{ marginTop: "1em" }}>
              <p>
                A solution to improve sanitation and utilize human excreta as a
                resource in rural and semi urban areas where the drainage
                network is weak.
                <br />
                <br />
                Rural and off-the-grid areas do not have proper sewage and
                drainage systems and the lack of proper toilets leads to open
                defecation. There is excess of human excreta and after being
                converted to compost, it is pathogen free and can be safely
                applied to farms.
              </p>
            </div>
          </Grid.Column>

          <Grid.Column width={8}>
            <ul>
              <li>My Role: end to end Product Development</li>
              <li>
                <a
                  href="http://www.ipindia.nic.in/writereaddata/Portal/IPOJournal/1_1521_1/Part-2_Designs.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Device for Making Compost, Design Number 286447, Class 09-03
                </a>
                : Registered under the Designs Act, 2000, Government of India
                and jointly held with NID.
              </li>
              <li>Duration: Mar - Apr 2016 (8 weeks)</li>
              <li>Guide: Dr. Vikram Parmar (National Institute of Design)</li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ marginBottom: "2%" }}>
          <Grid.Column width={6}>
            <Header as="h3">Product Details</Header>
            <span>
              The product is a self balancing container on wheels which fits
              under a raised toilet platform. Half of the container fits under
              the platform while the other half remains outside, attached to the
              toilet wall. <br />
              <br />
              Human excreta falls into the container under the platform and
              sawdust, dry hay etc. is added from the outside through a funnel.
              Over a period of a few weeks, this combination gets converted to
              manure.
              <br />
              <br />
              Grey water i.e. urine etc. percolates through the contents of the
              container, collects in a receptacle under the toilet and gets
              absorbed by the soil.
            </span>
          </Grid.Column>
          <Grid.Column width={10}>
            <Image src={sendriya_storyboard} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <Image src={sendriya_materials} />
            <p style={{ fontStyle: "italic", textAlign: "center" }}>
              materials required to build the composting toilet
            </p>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}

        <Grid.Row centered>
          <Header as="h2">II. A Female Sanitation Device - UREE</Header>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={7}>
            <Image src={uree_ideation_1} />
          </Grid.Column>
          <Grid.Column width={9} style={{ gutterWidth: 0 }}>
            <Embed
              id="182443200"
              source="vimeo"
              placeholder={uree_header_1}
              autoplay={true}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "4%" }}>
          <Grid.Column width={7}>
            <p style={{ marginTop: "1em" }}>
              A minimum water consuming urinal for women to make urinating in
              public areas hygienic and hassle-free.
            </p>
          </Grid.Column>
          <Grid.Column width={9}>
            <ul>
              <li>My Role: Industrial Design Intern</li>
              <li>Duration: May - Jul 2015 (8 weeks)</li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "4%" }}>
          <Grid.Column width={4}>
            <HeroImage
              src={uree_proto_1}
              imageHeight="40vh"
              caption="1:1 prototype process"
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <HeroImage
              src={uree_proto_2}
              imageHeight="40vh"
              caption="structural strength exploration"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <HeroImage
              src={uree_proto_4}
              imageHeight="40vh"
              caption="1:1 prototype"
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <HeroImage
              src={uree_proto_3}
              imageHeight="40vh"
              caption="model with simulation"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <span>
              Women squat over Indian style pans and hover or sit on the western
              style toilets depending on how clean they look and how clean they
              are perceived to be. Females who are pregnant, overweight, have
              arthritis or a temporary disability like muscle pull/ fracture and
              find it challenging to squat over the Indian pan. Uree is designed
              to make this experience comfortable.
            </span>
          </Grid.Column>
          <Grid.Column width={8}>
            <span>
              The slope of Uree is calculated to prevent splash-back in a seated
              or squatting position. The seat is lightweight, detached from the
              toilet and has a separate frame for load bearing. The urinal can
              be screwed on to the floor, the plumbing system is concealed
              inside, thus making it easy to install.
            </span>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}
        <Grid.Row centered>
          <Header as="h2">III. A Dustbin for the Indian Railways</Header>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column>
            <Image src={dustbin_header_2} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={9}>
            <p style={{ marginTop: "1em" }}>
              A large, durable and inviting dustbin for the sleeper compartment
              of the Indian Railways. <br />
              <br />
              At the time the research was conducted, very few trains with
              compartments other than first class and 2nd AC had dustbins. The
              project involved studying hygiene patterns of people, the cleaning
              practices of the Indian Railways and potential barriers in introducing a
              dustbin.
            </p>
          </Grid.Column>
          <Grid.Column width={7}>
            <ul>
              <li>Duration: Jan - Feb 2015 (6 weeks)</li>
              <li>
                Guides: Divya Dave and Praveen Nahar (National Institute of
                Design)
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={6}>
            <Header as="h3">Product Details</Header>
            <span>
              Dustbins in the railways are outside the compartment's seating/
              sleeping area and are usually on the outside wall of the toilet.
              <br />
              <br />
              The proposed dustbin is placed under the washbasin with an opening
              on its side. The side opens to the passageways to the toilets and
              to the connecting compartment. The side opening placement
              facilitates simultaneous usage of the dustbin and washbasin.
              <br />
              <br />
              The bin in itself is lightweight, has a strong anti-theft locking
              mechanism and a foot insert at the bottom of the frame provides
              stability and a counter balance when the dustbin is emptied by
              pivoting over the horizontal rod on the side.
            </span>
          </Grid.Column>
          <Grid.Column width={10} style={{ marginBottom: "2%" }}>
            <Image src={dustbin_diagram} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={6}>
            <HeroImage
              src={dustbin_ideation_2}
              imageHeight="35vh"
              caption="What is thrown out of the train window"
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <HeroImage
              src={dustbin_ideation_1}
              imageHeight="35vh"
              caption="Ideation of form and function"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <HeroImage
              src={dustbin_header_1}
              imageHeight="35vh"
              caption="Expected method of use"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
