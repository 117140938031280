import React, { useRef } from "react";
import {
  Button,
  Grid,
  Icon,
  Image,
  Header,
  Embed,
  Container,
} from "semantic-ui-react";

/* cSpell:enable */
import CenteredImage from "../../components/CenteredImage";
import LinkButton from "../../components/LinkButton";
import HorizontalRule from "../../components/HorizontalRule";
import SiteFooter from "../../components/SiteFooter";

const digitalindia = "images/DigitalIndia/digitalindia_hero.png";
const sitemap = "images/DigitalIndia/sitemap.png";
const logo_MoHUA = "images/DigitalIndia/logo_MoHUA.png";
const logo_DigitalGreen = "images/DigitalIndia/logo_DigitalGreen.png";
const logo_Gauteng = "images/DigitalIndia/logo_Gauteng.jpg";
const videoPlaceholder = "images/DigitalIndia/videoPlaceholder.png";

// work
const mobileQuizPrevious = "images/DigitalIndia/mobileQuizPrevious.png";
const mobileQuizNew = "images/DigitalIndia/mobileQuizNew.png";
const quizDeconstructed = "images/DigitalIndia/quizDeconstructed.png";
const quizWebDeconstructed = "images/DigitalIndia/quizWebDeconstructed.png";
const previousAnalytics = "images/DigitalIndia/previousAnalytics.png";
const analyticsDeconstructed = "images/DigitalIndia/analyticsDeconstructed.png";

// features
const features_Analytics = "images/DigitalIndia/features_Analytics.png";
const features_Bandwidth = "images/DigitalIndia/features_Bandwidth.png";
const features_Language = "images/DigitalIndia/features_Language.png";
const features_Simple = "images/DigitalIndia/features_Simple.png";

// empty states
const chats = "images/DigitalIndia/emptyState_Chats.png";
const certificate = "images/DigitalIndia/emptyState_Certificate.png";
const noCourses = "images/DigitalIndia/emptyState_noCourses.png";
const noDownloads = "images/DigitalIndia/emptyState_noDownloads.png";
const noInternet = "images/DigitalIndia/emptyState_noInternet.png";
const noSearchResults1 = "images/DigitalIndia/emptyState_noSearchResults1.png";
const noSearchResults2 = "images/DigitalIndia/emptyState_noSearchResults2.png";
const unexpectedError = "images/DigitalIndia/emptyState_unexpectedError.png";

// other work
const ms_agriculture = "images/DigitalIndia/ms_agriculture.jpg";
const ms_aadhaar = "images/DigitalIndia/ms_aadhaar.png";
const ms_healthcare = "images/DigitalIndia/ms_healthcare.jpg";

export default function DigitalIndia() {
  const backRef = useRef(null);

  return (
    <>
    <Container>
      <Grid stackable columns>
        <Grid.Row centered>
          <div ref={backRef}>
            <Header as="h2" style={{ marginBottom: "4%" }}>
              Microsoft Community Training
            </Header>
          </div>
          <Image src={digitalindia} />
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Intro and Role */}

        <Grid.Row style={{ margin: "2% 0px 0px" }}>
          <Grid.Column width={11}>
            <Header as="h3" style={{ marginRight: "30%" }}>
              Redesign of key mobile and web experiences of Microsoft Community
              Training to increase engagement and user retention.
            </Header>
            <p style={{ marginBottom: "3%" }}>
              Microsoft Community Training is a Learning Management System to
              enable skilling at scale for blue-collar workers in an accessible
              and convenient manner. Post training, users are matched with
              relevant jobs via LinkedIn. It is an Azure-enabled SaaS and PaaS
              model.
            </p>

            <LinkButton
              link="https://play.google.com/store/apps/details?id=com.thintimillion.thintimillion"
              color="#461a52"
              text="App on Play Store"
            />

            <LinkButton
              link="https://microsoftcommunitytraining.com/"
              color="#461a52"
              text="Community Training Website"
            />
          </Grid.Column>

          <Grid.Column width={1} />

          <Grid.Column width={4}>
            <Header as="h4">My Role</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>
              UX Design, UI Design, UX Workflows
            </p>

            <Header as="h4">Team</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>
              Me, 1 Design Lead, 1 Senior User Researcher, 2 PMs, 11 Developers
            </p>

            <Header as="h4">Duration</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>
              6 months (Dec 2017 - Jun 2018)
            </p>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />

        {/* ________________________________________________________________________________ */}
        {/* Features Image */}
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">Features</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ margin: "-1% 0px 8%" }}>
          <Grid.Column width={3} textAlign="center">
            <CenteredImage
              src={features_Language}
              displayImageAlt="multi language file format and support"
              imageCaption="Multi Language file format and support"
              imageHeight="5em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={3} textAlign="center">
            <CenteredImage
              src={features_Simple}
              displayImageAlt="Simplified learning experience"
              imageCaption="Simplified learning experience"
              imageHeight="5em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={3} textAlign="center">
            <CenteredImage
              src={features_Analytics}
              displayImageAlt="Analytics for tracking performance"
              imageCaption="Analytics for tracking performance"
              imageHeight="5em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={3} textAlign="center">
            <CenteredImage
              src={features_Bandwidth}
              displayImageAlt="Low bandwidth optimization"
              imageCaption="Low bandwidth optimization"
              imageHeight="5em"
            ></CenteredImage>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">Success Stories</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ marginBottom: "8%" }}>
          <Grid.Column width={5}>
            <CenteredImage
              src={logo_Gauteng}
              displayImageAlt="Gauteng Provincial Government Logo"
              imageCaption="Running Thint’iMillion, a digital up-skilling platform customized for South African youth. Planned to be used by 4 million people by this year."
              imageHeight="7em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={5}>
            <CenteredImage
              src={logo_MoHUA}
              displayImageAlt="Ministry of Housing and Urban Affairs Logo"
              imageCaption="MoHUA creates sanitation awareness among municipal functionaries to achieve the Clean India Mission. Used by more than 100,000 users across 4000 cities."
              imageHeight="7em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={4}>
            <CenteredImage
              src={logo_DigitalGreen}
              displayImageAlt="Digital Green Logo"
              imageCaption="DG uses the platform to train and assess farmers and frontline workers in different states of India."
              imageHeight="7em"
            ></CenteredImage>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        <Grid.Row style={{ marginBottom: "2%" }}>
          <Grid.Column width={11}>
            <Header as="h3">Learner side Sitemap</Header>
            <p>
              Multiple users such as learners, admins, and super-admins
              interact with the platform. Apart from the highlighted areas
              below, I worked on the <strong>Analytics Experience</strong> for
              administrators and made{" "}
              <strong>Illustrations for Empty and Error States.</strong> The
              designs have been implemented, released, and are actively being
              used.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <Image src={sitemap} />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3">Quiz and Exam Experience</Header>
            <Header
              as="h5"
              style={{
                margin: "-0.5em 0px 3%",
                color: "#545454",
              }}
            >
              Make it easier for trainers to assess their candidates
              <br />
              Keep learners engaged throughout the process
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ marginBottom: "8%" }}>
          <Grid.Column width={1} />
          <Grid.Column width={3} style={{ marginRight: "1em" }}>
            <Image src={mobileQuizPrevious} />
          </Grid.Column>

          <Grid.Column width={3}>
            <p>
              <strong>Before</strong>
              <br />
              Answer categorization is not clear, users with low marks are
              discouraged and drop off, single scroller display of Q&A is not
              scalable
            </p>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={3} style={{ marginRight: "1em" }}>
            <Image src={mobileQuizNew} />
          </Grid.Column>

          <Grid.Column width={3}>
            <p>
              <strong>After</strong>
              <br />
              Answers categories are marked with fraction indicators, message strings
              are made more engaging and encouraging, content wrapped in accordions
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column>
            <Header as="h4" style={{ color: "#545454" }}>
              Considerations
            </Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "0.5em" }}>
                What is the <b>most visual and cognitive way</b> to display
                correct, incorrect and skipped answers?
              </li>
              <li style={{ marginBottom: "0.5em" }}>
                How can the <b>number of steps be minimized</b> to enable a user
                to view the correct answer?
              </li>
              <li style={{ marginBottom: "0.5em" }}>
                What if a{" "}
                <b>learner presses submit without attempting all questions?</b>
              </li>
              <li>
                Should we <b>use numbers, emojis or symbols</b> to depict
                correct, incorrect and skipped?
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Header as="h4" style={{ color: "#545454" }}>
              Deconstruction
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ margin: "-1.5em 0px 10%" }}>
          <Grid.Column width={13}>
            <Image src={quizDeconstructed} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Header as="h4" style={{ color: "#545454" }}>
              Responsive Design for Web and Tablet
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <Image src={quizWebDeconstructed} />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3">Analytics Dashboard</Header>
            <Header
              as="h5"
              style={{
                marginTop: "-0.5em",
                color: "#545454",
              }}
            >
              Give facilitators a means to measure the health of their program
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "10%" }}>
          <Grid.Column width={7}>
            <Header as="h4" style={{ marginTop: "1.5em", color: "#545454" }}>
              Requirements
            </Header>

            <ul>
              <li>Provide glanceable Information</li>
              <li>Make information more visual and insightful</li>
            </ul>

            <Header as="h4" style={{ marginTop: "4em", color: "#545454" }}>
              Considerations
            </Header>

            <ul>
              <li>Different levels of control and insight based on Role</li>
              <li>User goals of different roles</li>
              <li>Software packages used to build the visualizations</li>
            </ul>
          </Grid.Column>

          <Grid.Column width={8}>
            <Image src={previousAnalytics} />
            <p style={{ fontStyle: "italic", textAlign: "center" }}>
              Previous Analytics Experience
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Header as="h4" style={{ color: "#545454" }}>
              Redesigned Analytics Deconstruction
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <Image src={analyticsDeconstructed} />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
      </Grid>

      {/* ________________________________________________________________________________ */}
      {/* Illustrations and Empty States */}
      <Grid doubling columns={4}>
        <Grid.Row style={{ marginBottom: "2%" }}>
          <Grid.Column width={11}>
            <Header as="h3">Illustrations for Empty and Error States</Header>
            <p>
              The team’s intention was to make the empty and error states have a{" "}
              <b>traditional feel to them, yet be country agnostic.</b> I
              started collecting cultural references of crafts, prints and
              artefacts from India, South Africa, South East Asia etc. and
              translated them into illustrations. The following illustrations
              came to be after 11-12 rounds of iterations.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={4}>
            <CenteredImage
              src={chats}
              displayImageAlt="empty state for start chatting"
              imageCaption="Start Chatting"
              imageHeight="12em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={4}>
            <CenteredImage
              src={noCourses}
              displayImageAlt="empty state for no courses"
              imageCaption="No Courses"
              imageHeight="12em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={4}>
            <CenteredImage
              src={noInternet}
              displayImageAlt="empty state for connection broken"
              imageCaption="Connection Broken"
              imageHeight="12em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={4}>
            <CenteredImage
              src={noDownloads}
              displayImageAlt="empty state for no downloads"
              imageCaption="No Downloads"
              imageHeight="12em"
            ></CenteredImage>
          </Grid.Column>
        </Grid.Row>

        {/* __________________________ */}

        <Grid.Row centered style={{ marginBottom: "10%" }}>
          <Grid.Column width={4}>
            <CenteredImage
              src={unexpectedError}
              displayImageAlt="empty state for wrong URL"
              imageCaption="Wrong URL"
              imageHeight="12em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={4}>
            <CenteredImage
              src={certificate}
              displayImageAlt="empty state for certificate"
              imageCaption="Certificate Received"
              imageHeight="12em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={4}>
            <CenteredImage
              src={noSearchResults1}
              displayImageAlt="empty state for no search results"
              imageCaption="No Search Results"
              imageHeight="12em"
            ></CenteredImage>
          </Grid.Column>

          <Grid.Column width={4}>
            <CenteredImage
              src={noSearchResults2}
              displayImageAlt="empty state for unexpected error"
              imageCaption="Unexpected Error"
              imageHeight="12em"
            ></CenteredImage>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {/* ________________________________________________________________________________ */}
      {/* Video of Microsoft Community Training */}

      <Grid stackable columns>
        <Grid.Row>
          <Grid.Column width={6} style={{ marginTop: "1.5em" }}>
            <Header as="h3">Community Training in Action</Header>
            <p>
              Microsoft partnered with{" "}
              <a
                href="http://mohua.gov.in/"
                target="blank"
                rel="noopener noreferrer"
                title="Ministry of Housing and Urban Affairs"
              >
                MoHUA
              </a>{" "}
              (Government of India) to provide training to municipal
              functionaries and officers for the Clean India (Swachh Bharat)
              Mission. <br />
              <br />
              The initiative has helped train{" "}
              <strong>
                110,000+ municipal functionaries across 4,000+ cities
              </strong>{" "}
              on best sanitation practices. It has been humbling to see the
              impact of the team's work.
            </p>
          </Grid.Column>
          <Grid.Column width={10} style={{ gutterWidth: 0 }}>
            <Embed
              id="IFN0Ux7T_qE"
              source="youtube"
              placeholder={videoPlaceholder}
              style={{ borderRadius: "0.5em" }}
            />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}
        {/* Other Work done at Microsoft */}

        <Grid.Row style={{ marginBottom: "3%" }}>
          <Grid.Column width={12}>
            <Header as="h3">Other Contributions</Header>
            <p>
              The team works on 4 verticals - Education/ Skilling, Healthcare,
              Agriculture and Identity Authentication; deploying services
              through Azure. Microsoft Community Training is also an initiative
              of Team Digital India. These are some of the other projects I
              worked on:{" "}
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column textAlign="center" width={5}>
            <CenteredImage
              src={ms_agriculture}
              displayImageAlt="Gauteng Provincial Government Logo"
              imageHeight="12em"
            ></CenteredImage>
            <Header as="h4" style={{ color: "#545454", marginTop: "-0.5em" }}>
              Digital Agriculture
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              Created a{" "}
              <strong>subsidized fertilizer purchase application</strong>{" "}
              for farmers and an <strong>agriculture insights dashboard</strong>{" "}
              for the Government of India.
            </p>
          </Grid.Column>

          <Grid.Column textAlign="center" width={5}>
            <CenteredImage
              src={ms_aadhaar}
              displayImageAlt="Ministry of Housing and Urban Affairs Logo"
              imageHeight="12em"
            ></CenteredImage>

            <Header as="h4" style={{ color: "#545454", marginTop: "-0.5em" }}>
              Authentication using Aadhaar
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              Designed an end-to-end login experience for authenticating to Microsoft services using
              Aadhaar (similar to SSN).
              <br />
            </p>
          </Grid.Column>

          <Grid.Column textAlign="center" width={5}>
            <CenteredImage
              src={ms_healthcare}
              displayImageAlt="Digital Green Logo"
              imageHeight="12em"
            ></CenteredImage>

            <Header as="h4" style={{ color: "#545454", marginTop: "-0.5em" }}>
              AI x Cardiovascular Risk Score
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
            Created an <a href="https://news.microsoft.com/en-in/microsoft-and-apollo-hospitals-introduce-ai-powered-cardiovascular-disease-risk-score/" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>AI assistant</u></a>&nbsp;
            for doctors to assess patients’ risk of heart attacks and early detection 
            of cardiovascular diseases, in partnership with Apollo Hospital 
            – one of India’s biggest hospital chains.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ margin: "8% 0px" }}>
          <Grid.Column verticalAlign="middle">
            <Button
              onClick={() => {
                backRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "start",
                });
              }}
            >
              Back to Top
              <Icon
                name="caret up"
                alt="caret up"
                style={{ marginLeft: "0.5em", marginRight: "-0.5em" }}
              />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
    <SiteFooter />
    </>
  );
}
