import React from "react";
import { Image } from "semantic-ui-react";

// define a reusable component as an arrow function
const CenteredImage = ({ src, displayImageAlt, imageCaption, imageHeight }) => {
  return (
    <>
      <Image
        src={src}
        alt={displayImageAlt}
        style={{
          objectFit: "cover",
          width: "auto",
          height: "auto",
          maxWidth: "100%",
          maxHeight: imageHeight,
          paddingBottom: "0.5em",
        }}
        centered
      />
      <p style={{ marginTop: "5px", textAlign: "center" }}>{imageCaption}</p>
    </>
  );
};

export default CenteredImage;
