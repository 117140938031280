import React, { Component } from "react";
import { Menu, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default class NavBar extends Component {
  state = { activeItem: "Work" };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    return (
      <div style={{ padding: "1%" }}>
        <Menu secondary size="huge">
          <Menu.Item as={Link} to="/" onClick={this.handleItemClick}>
            <Image src="images/me.gif" alt="placeholder" size="tiny" />
          </Menu.Item>

          <Menu.Menu position="right">
            <Menu.Item
              as={Link}
              to="/"
              name="Featured"
              active={activeItem === "WORK"}
              onClick
              ={this.handleItemClick}
            />


            <Menu.Item
              as={Link}
              to="/sandbox"
              name="All"
              active={activeItem === "SANDBOX"}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              as={Link}
              to="/community"
              name="Community"
              active={activeItem === "COMMUNITY"}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              as={Link}
              to="/aboutme"
              name="About"
              active={activeItem === "ABOUT"}
              onClick={this.handleItemClick}
            />
            {/* <Dropdown
              item
              as={Link}
              to="/sandbox"
              text="SandBox"
              active={activeItem === "SANDBOX"}
              onClick={this.handleItemClick}
            >
              <DropdownMenu>
                <DropdownItem>Electronics</DropdownItem>
                <DropdownItem>Automotive</DropdownItem>
                <DropdownItem>Home</DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}
