import React, { useState } from "react";
import {
  Container,
  Form,
  Grid,
  Header, Icon,
  Input,
  Button,
} from "semantic-ui-react";

import SiteFooter from "../components/SiteFooter";

const PasswordProtection = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onPasswordSubmit(password);
  };

  return (
    <>
    <Container style={{minHeight: '50vh'}}>
      <Grid centered stackable>
        <Grid.Row>
          <Grid.Column width={6} verticalAlign="middle">
          <Icon circular inverted color="grey" name='lock' size="large" />
            <Header as="h3">This page is password protected</Header>
            <Form onSubmit={handleSubmit}>
              <Form.Field>
                <Input
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Field>
              <Button type="submit">Open Sesame!</Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>

<SiteFooter style={{position: "fixed", bottom: 0}}/>
</>
  );
};

export default PasswordProtection;
