import React from "react";
import { Embed, Grid, Header, Image } from "semantic-ui-react";
/* cSpell:disable */

const mlc_header_2 = "images/SandBox/SpecialNeeds/mlc_header_2.jpg";
const mlc_video_1 = "images/SandBox/SpecialNeeds/mlc_video_1.jpg";
const mlc_video_2 = "images/SandBox/SpecialNeeds/mlc_video_2.jpeg";

export default function MobileLifeCenter() {
  return (
    <>
      <Grid>
        <Grid.Row centered>
          <Image src={mlc_header_2} rounded />
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%", marginTop: "1em" }}>
          <Grid.Column width={8}>
            <Header as="h3">
              A vibro-tactile aid for the deaf-blind, deaf and blind to make
              them experience time through music.{" "}
            </Header>
            <p>
              Vibro-tactile: relating to the perception of vibration through
              touch
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h4">My Role</Header>
            <p style={{ margin: "-2% 0px 2%" }}>HCI Research Intern<br />
            <a href="http://www.mobilelifecentre.org/" target="_blank"rel="noopener noreferrer">Mobile Life Centre, Stockholm</a></p>


            <Header as="h4">Duration</Header>
            <p style={{ margin: "-2% 0px 2%" }}>September 2015 (4 weeks)</p>

            <Header as="h4">Advisors</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              Dr. Elsa Kosmack Vaara (Mobile Life Centre, Stockholm)
              <br />
              Dr. Cheryl Akner-Koler (Konstfack University)
            </p>
          </Grid.Column>
        </Grid.Row>
{/* ________________________________________________________________________________ */}
        <Grid.Row style={{ marginBottom: "2%" }}>
          <Grid.Column width={8}>
            <Header as="h3">User Research and Experiments</Header>
            <span>
              We perceive time through change. The brains of the deaf-blind, deaf and
              blind rewire and adapt to enhance the remaining senses. By adjusting the speed,
              direction, and intensity of music, we amplified the users' perception of time.
            </span>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3">Vibro-Tactile Stimuli</Header>
            <span>
              Vibro-tactile stimuli at specific intervals give a sense of beats,
              and beats are temporal. We conducted experiments using a bass pump
              and small vibrators to understand how music vibrations transmit
              through different materials.
            </span>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={8}>
            <Embed id="151751092" placeholder={mlc_video_1} source="vimeo" />
            <p style={{ marginTop: "1em" }}>
              This video shows our experiments, prototypes,
              user studies based on how we connected the dots between seemingly unrelated entities - time,
              sound and vibrations. 
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Embed id="151750505" placeholder={mlc_video_2} source="vimeo" />
            <p style={{ marginTop: "1em" }}>
              The insights from conducting experiments were used to create a
              vibro-tactile scarf cum sweater. The scarf can also be used by the elderly and individuals who seek an
              experiential music experience.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
