import { Grid, Header,Image, Container } from "semantic-ui-react";
import { Link } from "react-router-dom";

import SiteFooter from "../components/SiteFooter";
import ModalScrolling from "../components/ModalScrolling";

import DesignforSpecialNeeds from "./SandBox/DesignforSpecialNeeds";
import MobileLifeCenter from "./SandBox/MobileLifeCenter";
import CompostingToilet from "./SandBox/CompostingToilet";
import Uree from "./SandBox/Uree";
import IndianRailways from "./SandBox/IndianRailways";
import WhirlpoolFridge from "./SandBox/WhirlpoolFridge";
import ElectroluxMicrowave from "./SandBox/ElectroluxMicrowave";
import Hyperloop from "./SandBox/Hyperloop";


const hp_header2 = "images/SandBox/HealthPal/hp_header2.png";
const dip_header_2 = "images/SandBox/CancerCare/dip_header_2.png";
const mlc_header_1 = "images/SandBox/SpecialNeeds/mlc_header_1.jpg";
const amm_header_2 = "images/SandBox/SpecialNeeds/amm_header_2.png";
const sendriya_header_3 = "images/SandBox/Sanitation/sendriya_header_3.jpg";
const dustbin_header_1 = "images/SandBox/Sanitation/dustbin_header_1.jpg";
const uree_header_2 = "images/SandBox/Sanitation/uree_header_2.jpg";
const whirlpool_header_2 = "images/SandBox/Appliances/whirlpool_header_2.jpg";
const electrolux_header_3 = "images/SandBox/Appliances/electrolux_header_3.jpg";
const hyperloop_header="./images/SandBox/Hyperloop/hyperloop_hero.png";

export default function SandBox() {
  return (
    <>
      <Container>
        <Grid stackable>
          <Grid.Row centered>
            <Grid.Column width={8} textAlign="center">
              <Header as="h1">SandBox</Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{marginBottom: "8%"}}>
            <Grid.Column width={4}>
              <p style={{ fontSize: "12px" }}>SERVICE DESIGN</p>
              <Header as="h3">
                How might we build systems where cure is supplemented by care?
              </Header>
            </Grid.Column>

            <Grid.Column width={6}>
              <Image
                src={hp_header2}
                as={Link}
                size="large"
                to="/healthpal"
                rounded
              />
            </Grid.Column>

            <Grid.Column width={6}>
              <Image
                src={dip_header_2}
                as={Link}
                to="/cancercare"
                size="large"
                style={{ objectFit: 'cover' }}
                rounded
              />
            </Grid.Column>
          </Grid.Row>
          {/* ________________________________________________________________________________ */}
          
          <Grid.Row>
            <Grid.Column width={4}>
              <p style={{ fontSize: "12px" }}>INDUSTRIAL DESIGN</p>
              <Header as="h3">Formgiving</Header>
              <p style={{ marginTop: "-0.4em" }}>
                Solving problems in the tangible realm by visualizing and
                building in 3D
              </p>
            </Grid.Column>

            <Grid.Column width={3}>
              <ModalScrolling
                triggerCTA={
                  <Image
                    src={mlc_header_1}
                    size="medium"
                    rounded
                    as={Link}
                  />}
                  modalHeader="Vibro-tactile Aid for the Deaf-Blind"
                  modalBody={<MobileLifeCenter />}
               />
            </Grid.Column>

            <Grid.Column width={3}>
            <ModalScrolling triggerCTA={
                  <Image
                    src={electrolux_header_3}
                    size="medium"
                    rounded
                    as={Link}
                  />}
                  modalHeader="Microwave for a Shared Kitchen"
                  modalBody={<ElectroluxMicrowave />}
               />
            </Grid.Column>

            <Grid.Column width={3}>
            <ModalScrolling
                triggerCTA={
                  <Image
                    src={hyperloop_header}
                    size="medium"
                    rounded bordered
                    as={Link}
                  />}
                  modalHeader="Hyperloop - a new mode of transportation"
                  modalBody={<Hyperloop />}
               />
            </Grid.Column>

            <Grid.Column width={3}>
            <ModalScrolling
                triggerCTA={
                  <Image
                    src={sendriya_header_3}
                    size="medium"
                    as={Link}
                  />}
                  modalHeader="A Composting Toilet which converts excreta to manure"
                  modalBody={<CompostingToilet />}
               />
            </Grid.Column>
          </Grid.Row>
          {/* ________________________________________________________________________________ */}
          <Grid.Row>
            <Grid.Column width={4} />
            <Grid.Column width={3}>
            <ModalScrolling
                triggerCTA={
                  <Image
                    src={amm_header_2}
                    size="medium"
                    rounded bordered
                    as={Link}
                  />}
                  modalHeader="Economic Empowerment of Intellectually-Disabled Youth"
                  modalBody={<DesignforSpecialNeeds />}
               />
            </Grid.Column>

            <Grid.Column width={3}>
            <ModalScrolling
                triggerCTA={
                  <Image
                    src={whirlpool_header_2}
                    size="medium"
                    rounded
                    as={Link}
                  />}
                  modalHeader="A Fridge for Indian Millennials"
                  modalBody={<WhirlpoolFridge />}
               />
            </Grid.Column>

            <Grid.Column width={3}>
            <ModalScrolling
                triggerCTA={
                  <Image
                    src={uree_header_2}
                    size="medium"
                    rounded
                    as={Link}
                  />}
                  modalHeader="Uree - A urinal for females"
                  modalBody={<Uree />}
               />
            </Grid.Column>

            <Grid.Column width={3}>
            <ModalScrolling
                triggerCTA={
                  <Image
                    src={dustbin_header_1}
                    size="medium"
                    rounded
                    as={Link}
                  />}
                  modalHeader="A Dustbin for the Indian Railways"
                  modalBody={<IndianRailways />}
               />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <SiteFooter />
    </>
  );
}
