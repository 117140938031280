import React from "react";
import { Grid, Image, Header, Embed } from "semantic-ui-react";
/* cSpell:disable */

import HeroImage from "../../components/HeroImage";

const uree_header_1 = "images/SandBox/Sanitation/uree_header_1.jpg";
const uree_ideation_1 = "images/SandBox/Sanitation/uree_ideation_1.jpg";
const uree_proto_1 = "images/SandBox/Sanitation/uree_proto_1.jpg";
const uree_proto_2 = "images/SandBox/Sanitation/uree_proto_2.jpg";
const uree_proto_3 = "images/SandBox/Sanitation/uree_proto_3.jpeg";
const uree_proto_4 = "images/SandBox/Sanitation/uree_proto_4.jpg";

export default function Uree() {
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9} style={{ gutterWidth: 0 }}>
            <Embed
              id="182443200"
              source="vimeo"
              placeholder={uree_header_1}
              autoplay={true}
            />
          </Grid.Column>

          <Grid.Column width={7}>
            <Image src={uree_ideation_1}/>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%", marginTop: "1em" }}>
          <Grid.Column width={10}>
            <Header as="h3">
              A minimum water consuming urinal for women to make urinating in
              public restrooms hygienic and hassle-free.
            </Header>
          </Grid.Column>
          <Grid.Column width={6}>
            <Header as="h4">My Role</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              Industrial Design Intern <br />
              Ekam Eco Solutions, IIT Delhi
            </p>

            <Header as="h4">Duration</Header>
            <p style={{ margin: "-2% 0px 2%" }}>May - July 2015 (8 weeks)</p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row style={{ marginBottom: "4%" }}>
          <Grid.Column width={5}>
            <HeroImage
              src={uree_proto_1}
              imageHeight="36vh"
              caption="1:1 prototype process"
              cornerRadius="1.5%"
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <HeroImage
              src={uree_proto_2}
              imageHeight="36vh"
              caption="structural strength exploration"
              cornerRadius="1.5%"
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <HeroImage
              src={uree_proto_4}
              imageHeight="36vh"
              caption="1:1 prototype"
              cornerRadius="1.5%"
            />
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}

        <Grid.Row style={{marginBottom: "8%"}}>
          <Grid.Column width={11}>
            <p>
              Women in India choose between squatting over pan toilets or using
              the Western-style toilets based on perceived cleanliness. However,
              factors like being pregnancy, overweight, having arthritis, or
              temporary disabilities make squatting challenging. Uree is
              designed to make this experience comfortable.
            </p>
            <br />
            <p>
              The slope of Uree is calculated to prevent splash-back in seated
              or squatting positions. The seat is lightweight, detached from the
              toilet with a separate frame for load bearing. The urinal can be
              screwed on to the floor, and the plumbing system is concealed,
              making it easy to install.
            </p>
          </Grid.Column>

          <Grid.Column width={5}>
            <Image src={uree_proto_3} rounded />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
