import React from "react";
import { Divider, Grid } from "semantic-ui-react";

export enum DividerSize {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
}

export type Props = {
  size: DividerSize;
};

export default function HorizontalRule({ size }: Props) {
  let columnWidth: any;

  switch (size) {
    case DividerSize.Small:
      columnWidth = 8;
      break;
    case DividerSize.Medium:
      columnWidth = 12;
      break;
    case DividerSize.Large:
      columnWidth = 16;
      break;
  }
  return (
    <Grid.Row centered style={{ margin: "8% 0px 6%" }}>
      <Grid.Column width={columnWidth}>
        <Divider />
      </Grid.Column>
    </Grid.Row>
  );
}
