import { useRef } from "react";

import {
  Button,
  Container,
  Embed,
  Grid,
  Header,
  Icon,
} from "semantic-ui-react";

/* cSpell:disable */

import CenteredImage from "../../components/CenteredImage";
import ReadMoreLink from "../../components/ReadMoreLink";
import ContentHeader from "../../components/ContentHeader";
import HorizontalRule from "../../components/HorizontalRule";

const hp_header = "images/SandBox/HealthPal/hp_header.png";
const hp_process = "images/SandBox/HealthPal/hp_process.png";
const hp_valueflow = "images/SandBox/HealthPal/hp_valueflow.png";
const hp_ecosystem = "images/SandBox/HealthPal/hp_ecosystem.png";
const hp_competitors = "images/SandBox/HealthPal/hp_competitors.png";
const hp_SET = "images/SandBox/HealthPal/hp_SET.png";

const hp_blood = "images/SandBox/HealthPal/hp_blood.png";
const hp_exercise = "images/SandBox/HealthPal/hp_exercise.png";
const hp_food = "images/SandBox/HealthPal/hp_food.png";
const hp_medicine = "images/SandBox/HealthPal/hp_medicine.png";

const hp_sugar = "images/SandBox/HealthPal/hp_sugar.png";
const hp_sugarMobile = "images/SandBox/HealthPal/hp_sugarMobile.png";
const hp_activity = "images/SandBox/HealthPal/hp_activity.png";
const hp_activityMobile = "images/SandBox/HealthPal/hp_activityMobile.png";
const hp_rewards = "images/SandBox/HealthPal/hp_rewards.png";
const hp_rewardsMobile = "images/SandBox/HealthPal/hp_rewardsMobile.png";

const hp_speedDating = "images/SandBox/HealthPal/hp_speedDating.png";
const hp_paperPrototypes = "images/SandBox/HealthPal/hp_paperPrototypes.png";
const hp_physicalCompanion = "images/SandBox/HealthPal/hp_physicalCompanion.png";
const hp_mvp2 = "images/SandBox/HealthPal/hp_mvp2.png";
const hp_kid = "images/SandBox/HealthPal/hp_kid.png";

const hp_videoPlaceholder = "images/SandBox/HealthPal/hp_videoPlaceholder.png";

//  ______________________________________________________________________________________________

export default function HealthPal() {
  const outcomeRef = useRef(null);
  const processRef = useRef(null);

  return (
    <Container>
      <Grid stackable>
        <Grid.Row centered>
          <Grid.Column textAlign="center">
            <Header as="h2">
              HealthPal- a care companion for diabetic kids
            </Header>
            <img src={hp_header} alt="HealthPal header" />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Intro and Role */}

        <Grid.Row style={{ margin: "2% 0px 8%" }}>
          <Grid.Column width={10}>
            <Header as="h3">
              Empower children with Type 1 Diabetes to self-manage their
              condition.
            </Header>
            <p style={{ marginTop: "1em" }}>
              <b>
                HealthPal is a digital - physical care companion for children
                with Type 1 Diabetes which helps them take ownership of their
                health from an early age.{" "}
              </b>
              The physical companion is a night-light cum health status
              indicator while the digital companion app gives children daily
              health goals to complete. Based on the completion of the goals by
              the child, HealthPal looks healthy or sick. To keep HealthPal
              healthy, children are motivated to take care of themselves.
            </p>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={5}>
            <Header as="h4">My Role</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>
              UX Workflows and Wireframes, Business Strategy, User Research
              Liaison, Tamagotchi design
            </p>

            {/* <Header as="h4">Tools and Methods</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>
              SET Analysis, Speed Dating, Co-creation, Wireframing
            </p> */}

            <Header as="h4">Team</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>3 Designers, 1 Engineer</p>

            <Header as="h4">Duration</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>4 months (Jan - May 2019)</p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* problem + supporting evidence */}

        <Grid.Row centered>
          <Grid.Column>
            <Header as="h3">Problem + Supporting Evidence</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ marginBottom: "8%" }}>
          <Grid.Column textAlign="center" width={4}>
            <Header as="h3">1.25 million</Header>
            <p>
              Americans have Type 1 Diabetes which is a lifelong condition and
              requires constant monitoring.
            </p>
          </Grid.Column>

          <Grid.Column textAlign="center" width={5}>
            <Header as="h3">Less than 33%</Header>
            <p>
              achieve target glucose levels. Managing this illness is extremely
              difficult, placing a huge burden on children and parents.
            </p>
          </Grid.Column>

          <Grid.Column textAlign="center" width={5}>
            <Header as="h3">4-6 yrs and 10-14 yrs</Header>
            <p>
              is the age when the incidence of Type 1 Diabetes peaks and
              children must learn from an early age to manage their condition.
            </p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* problem + supporting evidence */}

        <Grid.Row>
          <Grid.Column width={15}>
            <Header as="h3">Opportunities</Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "0.5em" }}>
                <strong>
                  Incentivize children by reflecting health behaviors in a
                  physical artefact
                </strong>
              </li>
              <li style={{ marginBottom: "0.5em" }}>
                <strong>Make healthcare fun and engaging</strong>
              </li>
              <li style={{ marginBottom: "0.5em" }}>
                <strong>
                  Help parents inculcate habits in their kid early on to manage
                  their condition
                </strong>
              </li>
              <li style={{ marginBottom: "0.5em" }}>
                <strong>
                  A companion which appeals to the target audience
                </strong>
              </li>
              <li>
                <strong>
                  A service that requires minimal data input - passively
                  collecting data from multiple devices
                </strong>
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginTop: "-1em", marginBottom: "8%" }}>
          <Grid.Column>
            <Button
              onClick={() => {
                outcomeRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "start",
                });
              }}
              style={{ marginRight: "1%" }}
            >
              Outcome <Icon name="caret right" />
            </Button>
            <Button
              onClick={() => {
                processRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "start",
                });
              }}
            >
              Research Process <Icon name="caret right" />
            </Button>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Measurable Health Metrics */}

        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3">Children with Type 1 Diabetes</Header>
            <Header as="h4" style={{ marginTop: "-0.5em", color: "#545454" }}>
              Health Metrics are measurable
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              Food Intake, Exercise and Medication help to keep blood sugar
              levels under control. It is important blood sugar levels remain in
              a certain range and don’t spike too high or too low.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column textAlign="center" width={4}>
            <CenteredImage
              src={hp_blood}
              displayImageAlt="blood sugar"
              imageHeight="7em"
            ></CenteredImage>
            <p>
              <strong>Sugar Levels</strong>
              <br />
              kids with diabetes test their blood sugar levels before meals and
              at bedtime.
            </p>
          </Grid.Column>

          <Grid.Column textAlign="center" width={4}>
            <CenteredImage
              src={hp_food}
              displayImageAlt="food intake"
              imageHeight="7em"
            ></CenteredImage>
            <p>
              <strong>Food Intake</strong>
              <br />
              eating foods high in sugar or carbohydrates can cause blood sugar
              spikes or drops.
            </p>
          </Grid.Column>

          <Grid.Column textAlign="center" width={4}>
            <CenteredImage
              src={hp_medicine}
              displayImageAlt="medication"
              imageHeight="7em"
            ></CenteredImage>
            <p>
              <strong>Medication</strong>
              <br />
              take insulin from syringes/ pumps or oral medicines regularly.
            </p>
          </Grid.Column>

          <Grid.Column textAlign="center" width={4}>
            <CenteredImage
              src={hp_exercise}
              displayImageAlt="activity levels"
              imageHeight="7em"
            ></CenteredImage>
            <p>
              <strong>Exercise</strong>
              <br />
              makes it easier to control blood sugar and may reduce the risk of
              other chronic illnesses.
            </p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Value Creation */}

        <Grid.Row>
          <Grid.Column>
            <ContentHeader
              titleh3="Perceived Value Creation"
              subtitle1="How the digital service offers value to all its stakeholders"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ marginBottom: "8%" }}>
          <Grid.Column width={15}>
            <img src={hp_valueflow} alt="healthpal value flow" />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Considerations */}
        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={9}>
            <Header as="h3">Considerations</Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "1em" }}>
                <strong>Engagement: </strong>Challenge the users just enough so
                they feel motivated to improve their health, but not so much
                that they feel disheartened.
              </li>
              <li style={{ marginBottom: "1em" }}>
                <strong>Competitive Differentiation: </strong>People are used to
                seeing their avatars, but how does it feel to take care of a
                digital manifestation of their health.
              </li>
              <li>
                <strong>Adaptability: </strong>Complement and not compete with
                existing health tracking, mindfulness, exercise applications
                that sync with HealthKit/ Apple Health.
              </li>
            </ul>
          </Grid.Column>
          <Grid.Column width={6} />
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Service Ecosystem */}

        <Grid.Row>
          <Grid.Column width={11}>
            <Header as="h3">HealthPal Service Ecosystem</Header>
            <p>
              Health Data from wearables and glucose sensors is reflected in the
              physical companion which{" "}
              <strong>changes color and moods based on the data</strong>. The
              companion app is child and parent facing, and the collected health
              metrics{" "}
              <strong>
                helps doctors and parents monitor the child’s health
                consistently.
              </strong>
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <img src={hp_ecosystem} alt="healthpal value flow" />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}
        {/* Presenting HealthPal*/}

        <Grid.Row style={{ marginBottom: "2em" }}>
          <Grid.Column>
            <div ref={outcomeRef}>
              <Header as="h2">Presenting HealthPal</Header>
            </div>
            <Header
              as="h5"
              style={{
                color: "#545454",
              }}
            >
              <ul style={{ listStyleType: "none", padding: "0px" }}>
                <li style={{ marginBottom: "1.2em" }}>
                  <span
                    role="img"
                    aria-label="Woman Tipping Hand: Medium-Light Skin Tone"
                    style={{ fontSize: "2em", paddingRight: "0.5em" }}
                  >
                    💁🏼‍♀️
                  </span>
                  Children complete daily goals to keep their companion healthy
                </li>
                <li>
                  <span
                    role="img"
                    aria-label=" Man Office Worker: Light Skin Tone"
                    style={{ fontSize: "2.1em", paddingRight: "0.5em" }}
                  >
                    👨🏻‍💼
                  </span>
                  Parents see their child’s health status
                </li>
              </ul>
            </Header>
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}
        {/* Home Screen*/}

        <Grid.Row style={{ marginBottom: "12%" }}>
          <Grid.Column width={5}>
            <Header as="h3" style={{ margin: "1.5em 0px" }}>
              Digital Companion App
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              HealthPal’s colour/ mood changes based on a health data received
              from wearables and glucose sensors.
              <br />
              <br />
              Children can customize the hair, clothing, accessories of their
              companion and also take snapshots.
            </p>
            <ReadMoreLink
              moreTitle="HealthPal’s Mood"
              lessTitle="HealthPal’s Mood"
            >
              People are wary of being tracked. Tracking health behaviors,
              especially diet and exercise, can become unhealthy and obsessive
              and demotivate people if it is too numbers-focused. Generalizing
              this information in emotions (happy/sad) was found to be more
              effective.
            </ReadMoreLink>

            <ReadMoreLink moreTitle="Why Customize?" lessTitle="Why Customize?">
              We learnt from user research that children love to create and
              customize.
            </ReadMoreLink>

            <ReadMoreLink
              moreTitle="Horizontal Orientation"
              lessTitle="Horizontal Orientation"
            >
              kids are more inclined to viewing and playing games and videos in
              the Horizontal view.
            </ReadMoreLink>
          </Grid.Column>

          <Grid.Column width={8}>
            <img src="images/SandBox/HealthPal/hp_home.gif" alt="home screen" />
          </Grid.Column>
          <Grid.Column width={3} />
        </Grid.Row>
        {/* ________________________________________________________________________________ */}
        {/* Blood Sugar Monitoring*/}

        <Grid.Row style={{ marginBottom: "12%" }}>
          <Grid.Column width={5}>
            <Header as="h3" style={{ margin: "1.5em 0px" }}>
              Blood Sugar Monitoring
            </Header>
            <span
              role="img"
              aria-label="Woman Tipping Hand: Medium-Light Skin Tone"
              style={{ fontSize: "2em" }}
            >
              💁🏼‍♀️
            </span>
            <p style={{ marginTop: "0.5em", marginBottom: "2em" }}>
              If their glucose level is out of range (too high or low), a
              <strong> suggestion</strong> to improve it pops up. If it stays in
              a good range, the children receive <strong>health points.</strong>
            </p>
            <span
              role="img"
              aria-label=" Man Office Worker: Light Skin Tone"
              style={{ fontSize: "2em" }}
            >
              👨🏻‍💼
            </span>
            <p>
              View weekly <strong> glucose level trends</strong> and also
              <strong> track A1C</strong>, another important metric that T1D's
              need to keep track of.
            </p>
          </Grid.Column>

          <Grid.Column width={8}>
            <img src={hp_sugar} alt="rewards" />
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: "2em" }}>
            <img src={hp_sugarMobile} alt="rewards" />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* activity tracking*/}

        <Grid.Row style={{ marginBottom: "12%" }}>
          <Grid.Column width={5}>
            <Header as="h3" style={{ margin: "1.5em 0px" }}>
              Activity Tracking
            </Header>
            <span
              role="img"
              aria-label="Woman Tipping Hand: Medium-Light Skin Tone"
              style={{ fontSize: "2em" }}
            >
              💁🏼‍♀️
            </span>
            <p style={{ marginTop: "0.5em", marginBottom: "2em" }}>
              <strong>Receive prompts</strong> from HealthPal to meet their
              daily goal, completion of which leads to
              <strong> health points.</strong>
            </p>
            <span
              role="img"
              aria-label=" Man Office Worker: Light Skin Tone"
              style={{ fontSize: "2em" }}
            >
              👨🏻‍💼
            </span>
            <p>
              Set daily activity goals. Can get their child's detailed
              <strong> activity trends over time</strong> such as distance
              traveled, steps and minutes active.
            </p>

            <ReadMoreLink
              moreTitle="No Manual Data Input"
              lessTitle="No Manual Data Input"
            >
              The activity data is collected through wearables, eliminating the
              need for inputting data manually.
            </ReadMoreLink>
          </Grid.Column>

          <Grid.Column width={8}>
            <img src={hp_activity} alt="rewards" />
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: "2em" }}>
            <img src={hp_activityMobile} alt="rewards" />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* rewards and incentives*/}

        <Grid.Row style={{ marginBottom: "12%" }}>
          <Grid.Column width={5}>
            <Header as="h3" style={{ margin: "1.5em 0px" }}>
              Rewards and Incentives
            </Header>
            <span
              role="img"
              aria-label="Woman Tipping Hand: Medium-Light Skin Tone"
              style={{ fontSize: "2em" }}
            >
              💁🏼‍♀️
            </span>
            <p style={{ marginTop: "0.5em", marginBottom: "2em" }}>
              Can <strong>redeem collected health points</strong> for customized
              rewards.
            </p>
            <span
              role="img"
              aria-label=" Man Office Worker: Light Skin Tone"
              style={{ fontSize: "2em" }}
            >
              👨🏻‍💼
            </span>
            <p>Decide the point based rewards.</p>

            <ReadMoreLink moreTitle="Why Rewards?" lessTitle="Why Rewards?">
              The health of the companion may not be enough of an incentive for
              children to be more healthy, therefore a rewards page was added.
              Parents found this a great way to incentivize kids to proactively
              take care of their health.
            </ReadMoreLink>
          </Grid.Column>

          <Grid.Column width={8}>
            <img src={hp_rewards} alt="rewards" />
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: "2em" }}>
            <img src={hp_rewardsMobile} alt="rewards" />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* physical companion */}

        <Grid.Row>
          <Grid.Column width={5} style={{ margin: "1.5em 0px" }}>
            <ContentHeader
              titleh3="Physical Companion"
              subtitle1="Night light cum Health Status Indicator"
            />
            <p>
              We are reframing the human emotion to care for the other; thus by
              taking care of their companion, children become their own care
              providers.
            </p>
            <ReadMoreLink
              moreTitle="Competitive Differentiation"
              lessTitle="Competitive Differentiation"
            >
              Having a physical manifestation of the child's health in the form
              of a night lamp gives us a competitive edge over other similar
              products in the market.
            </ReadMoreLink>
          </Grid.Column>

          <Grid.Column width={8}>
            <img
              src="images/SandBox/HealthPal/hp_physical.gif"
              alt="physical companion"
              style={{ borderRadius: "1.5em", width: "100%", padding: "1em" }}
            />
          </Grid.Column>
          <Grid.Column width={3} verticalAlign="middle">
            <p>
              <strong> Yellow - Positive:</strong> Glucose level in range
            </p>
            <p>
              <strong> Blue - Sad/ Sick:</strong> Glucose level not in range
            </p>
            <p>
              <strong> Pink- Joyous:</strong> Glucose level in range over a
              period of time
            </p>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}
        {/* Research Process/ SET analysis */}

        <Grid.Row>
          <Grid.Column>
            <div ref={processRef}>
              <Header as="h2" style={{ marginBottom: "1%" }}>
                Research Process
              </Header>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <img src={hp_process} alt="process" />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />

        <Grid.Row style={{ marginBottom: "1%" }}>
          <Grid.Column width={8}>
            <ContentHeader
              titleh3="SET Analysis"
              subtitle1="Analysis of Social, Economic and Technological Factors to gain a
                deeper understanding of the problem space, and to identify
                opportunities and risks."
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <img src={hp_SET} alt="SET analysis" />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />
        {/* ________________________________________________________________________________ */}
        {/* competitor study */}

        <Grid.Row>
          <Grid.Column width={6}>
            <Header as="h3" style={{ margin: "1.5em 0px" }}>
              Competitor Study
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              A large number of mobile apps exist that help in managing fitness
              and nutrition. A lot of them require{" "}
              <strong>manual input of data. </strong>
            </p>
            <p>
              Not many apps have a <strong>physical or IoT component.</strong>
            </p>
            <p>
              <strong>There isn't a great way to incentivize people </strong>
              to use these apps. People who are likely to download these apps
              are already pretty proactive about their health.
            </p>
          </Grid.Column>

          <Grid.Column width={1} />

          <Grid.Column width={9}>
            <img src={hp_competitors} alt="SET analysis" />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />
        {/* ________________________________________________________________________________ */}
        {/* speed dating */}

        <Grid.Row verticalAlign="middle">
          <Grid.Column width={8}>
            <img src={hp_speedDating} alt="speed dating storyboard" />
          </Grid.Column>
          <Grid.Column width={8}>
            <ContentHeader
              titleh3="Speed Dating"
              subtitle1="4 Doctors . 4 Children . 2 Parents . 5 Storyboards"
              style={{ margin: "1.5em 0px" }}
            />
            <p>
              We made storyboards in which we visualize how our solution
              functions and took it to our key stakeholders. Some of our
              findings include:
            </p>
            <p>
              <strong>Tamagotchi is for kids</strong>
              <br />
              Having a physical creature of reflection of health is great for
              engaging kids and helping them take control over their health, but
              less so for adults.
            </p>
            <p>
              <strong>People want multiple functionalities</strong>
              <br />
              such as the ability to collect data from multiple devices, send
              data to doctors, notify parents of kids’ health issues and
              schedule/ track appointments.
            </p>
            <p>
              <strong>Tracking can backfire</strong>
              <br />
              Tracking health behaviors, especially diet and exercise, can
              become unhealthy and obsessive/demotivate people if it is too
              numbers-focused. Generalizing this information in emotions
              (happy/sad) of a creature might be useful.
            </p>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />
        {/* ________________________________________________________________________________ */}
        {/* MVP V1 */}

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={9}>
            <Header as="h3">MVP Version 1</Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "1em" }}>
                Based on results from Speed-Dating, we decided to
                <strong>
                  {" "}
                  refine our target audience to children aged 6-13 with Type 1 &
                  Type 2 Diabetes.
                </strong>
              </li>
              <li style={{ marginBottom: "1em" }}>
                <strong>
                  Many kids in this age group have access to
                  smartphones/tablets, can read, and love the idea of virtual
                  pets{" "}
                </strong>
                (reflected in the success of WebKinz, Club Penguin, Tamagotchi,
                etc). Thus, they could use this app/physical companion to track
                their health.
              </li>
            </ul>
          </Grid.Column>
          <Grid.Column width={6} />
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={6}>
            <Header as="h4" style={{ color: "#545454" }}>
              Paper Prototypes
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              Since the app is focused towards children, we kept it simple and
              image heavy, such that learning curve is also minimal.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column>
            <img src={hp_paperPrototypes} alt="paper prototype" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h4" style={{ color: "#545454" }}>
              Physical Companion
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              We decided to go forward with a more gender-neutral form. This can
              double as a medication-storage container for kids who are also
              taking oral medications.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column>
            <img src={hp_physicalCompanion} alt="physical companion" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={11}>
            <Header as="h4" style={{ color: "#545454" }}>
              Insights and Feedback
            </Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "1em" }}>
                <strong>Data input should be minimal: </strong>People would not
                spend the time inputting data explicitly about medication or
                nutrition. For the product to be truly useful, it should
                passively collect data from multiple devices, such as
                phones/wearables, doctor’s appointments, and blood pressure
                cuffs.
              </li>
              <li style={{ marginBottom: "1em" }}>
                <strong>Leaderboard page might demotivate children </strong>when
                they see their friends doing better than them.
              </li>
              <li style={{ marginBottom: "1em" }}>
                <strong>Rewards are needed for compliance: </strong>
                According to a both doctors, adding rewards would incentivize
                children continuously use HealthPal.
              </li>
              <li>
                <strong>
                  Parents need to be involved in the care journey{" "}
                </strong>
                since they play a proactive role in ensuring that their child’s
                glucose remains in a healthy range.
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />
        {/* ________________________________________________________________________________ */}
        {/* MVP V2 */}
        <Grid.Row>
          <Grid.Column width={11}>
            <Header as="h3">MVP Version 2</Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "1em" }}>
                <strong>
                  We narrowed the age group 5-10 yr old children with Type I
                  Diabetes.{" "}
                </strong>
                Children start becoming quite mature post 8 years, and might not
                like to use something like our physical companion.
              </li>
              <li style={{ marginBottom: "1em" }}>
                Type 2 Diabetes is an adult onset diabetes, thus we
                <strong> just focused on Type 1 Diabetes. </strong>
                Children with Type 1 diabetes need to take insulin frequently
                which is injected through a pump, which led to the{" "}
                <strong>
                  removal of the medicine storage from the physical companion
                  and pill reminder feature from the app.
                </strong>
              </li>
              <li style={{ marginBottom: "1em" }}>
                To have no manual data input,
                <strong> we removed the nutrition tracking feature. </strong>
                Nutrition tracking is a vital part of T1D management, but for
                the sake of simplicity, not having at all in this version seemed
                a better trade-off.
              </li>
              <li style={{ marginBottom: "1em" }}>
                We
                <strong> added a rewards page to incentivize children </strong>
                to maintain their daily sugar and activity levels.
              </li>
            </ul>
          </Grid.Column>
          <Grid.Column width={6} />
        </Grid.Row>

        <Grid.Row style={{ margin: "1% 0px 8%" }}>
          <Grid.Column width={1} />
          <Grid.Column width={12}>
            <img src={hp_mvp2} alt="paper prototype" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h4" style={{ color: "#545454", margin: "1.5em 0px" }}>
              Insights and Feedback
            </Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "1em" }}>
                Children love to <strong>create and customize.</strong>
              </li>
              <li style={{ marginBottom: "1em" }}>
                They prefer
                <strong> interactions with big screens </strong>
                which allows for larger text and more visual content.
              </li>
              <li>
                Some of their favorite toys are
                <strong> anthropomorphized night lamps </strong>
                which motivated us to make HealthPal double as a night-light.
              </li>
            </ul>
          </Grid.Column>

          <Grid.Column width={5}>
            <img
              src={hp_kid}
              alt="paper prototype"
              style={{ borderRadius: "0.5em" }}
            />
          </Grid.Column>
          <Grid.Column width={3} />
        </Grid.Row>

        <HorizontalRule size="Small" />
        {/* ________________________________________________________________________________ */}
        {/* Recap/ Video */}

        <Grid.Row>
          <Grid.Column width={3} style={{ marginTop: "1em" }}>
            <Header as="h3">A Quick Recap</Header>
          </Grid.Column>
          <Grid.Column width={10} style={{ gutterWidth: 0 }}>
            <Embed
              id="K6TQCMPorR8"
              source="youtube"
              placeholder={hp_videoPlaceholder}
              style={{ borderRadius: "0.5em" }}
            />
          </Grid.Column>
          <Grid Column width={3} />
        </Grid.Row>

        <HorizontalRule size="Small" />
        {/* ________________________________________________________________________________ */}
        {/* Business Road-map*/}

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={10}>
            <Header as="h3" style={{ marginBottom: "1.5em" }}>
              Business Roadmap
            </Header>

            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "1em" }}>
                <strong>Voice Based Interactions: </strong>To take this project
                further, it would be worthwhile to explore speech as an
                additional interaction layer apart from visual cues.
              </li>
              <li style={{ marginBottom: "1em" }}>
                <strong>Nutrition Tracking: </strong>Building out food intake
                monitoring would require data input by the users to some extent,
                but will make our offering more holistic and take the MVP to its
                next step.
              </li>
              <li>
                <strong>
                  Expand to Chronic Illnesses + Healthy Children:{" "}
                </strong>
                Subsequently, HealthPal can help in managing chronic illnesses
                such as asthma and also cater to healthy kids.
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
