import React from "react";
import { Grid, Image, Header } from "semantic-ui-react";
/* cSpell:disable */

const amm_infographic = "images/SandBox/SpecialNeeds/amm_infographic.jpg";
const amm_solution_1 = "images/SandBox/SpecialNeeds/amm_solution_1.png";
const amm_solution_2 = "images/SandBox/SpecialNeeds/amm_solution_2.png";

export default function DesignforSpecialNeeds() {
  return (
    <>
      <Grid>
        <Grid.Row centered>
          <Image src={amm_infographic} fluid />
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%", marginTop: "1em" }}>
          <Grid.Column width={8}>
            <Header as="h3">
              Taking advantage of their abilities, how might we increase the
              economic productivity of intellectually disabled youth?{" "}
            </Header>

            <br />
            <p>
              1,505,624 individuals in India (0.12% of the total population)
              have arrested or incomplete development of the mind, specially
              characterized by sub-normality of intelligence.
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h4">Duration</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              October 2016 (3 weeks) <br />
              In collaboration with Apang Manav Mandal, Ahmedabad, India
            </p>

            <Header as="h4">Advisor</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              <a
                href="https://www.nid.edu/people/detail/gayatri-menon"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dr. Gayatri Menon
              </a>{" "}
              (National institute of Design)
            </p>
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}
        
        <Grid.Row>
          <Grid.Column width={12}>
          <Header as="h3">Value Creation</Header>
            <span>
              The aim was to unlock the untapped potential of intellectually
              disabled youth, create a unique and differentiated product compared to
              mass-manufactured goods, and monetize them by leveraging philanthropy. Workshops with differently-abled youth revealed key insights:
              <ul>
              <li>
                Instructions need to be given constantly. What may be intuitive
                to a person with an acceptable cognitive level, isn't suitable
                for someone with a lower cognition.
              </li>
              <li>
                Repetitive, simple, minimum-step-activities are most viable for
                cognitive accessibility.
              </li>
            </ul>
            </span>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "4%", alignItems: 'center'}}>
          <Grid.Column width={4}>
              <Header as="h3">Clip</Header>
              <span>
                Holds wires of different lengths in place. Simple knots can be
                made over earphones, USB cables, headphone wires etc. to make them
                tangle free. Each piece is handcrafted and unique.
              </span>
            </Grid.Column>
            <Grid.Column width={12}>
              <Image src={amm_solution_2}/>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ marginBottom: "8%", alignItems: 'center'}}>
            <Grid.Column width={4}>
              <Header as="h3">Block Printed Box</Header>
              <span>
                When small blocks are used to print
                in a circular symmetry, minor imperfections in alignment add up
                and render a product beautiful, setting it apart from mass
                manufactured goods.
              </span>
            </Grid.Column>
            <Grid.Column width={12}>
              <Image src={amm_solution_1} />
            </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
