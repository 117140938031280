import React from "react";
import { Grid, Image, Header} from "semantic-ui-react";
/* cSpell:disable */

import HeroImage from "../../components/HeroImage";
const electrolux_header_1 = "images/SandBox/Appliances/electrolux_header_1.jpg";
const electrolux_header_2 = "images/SandBox/Appliances/electrolux_header_2.jpg";
const electrolux_ideation_1 = "images/SandBox/Appliances/electrolux_ideation_1.jpg";
const electrolux_ideation_2 = "images/SandBox/Appliances/electrolux_ideation_2.jpg";
const electrolux_ideation_3 = "images/SandBox/Appliances/electrolux_ideation_3.jpg";

export default function ElectroluxMicrowave() {
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9}>
            <Image src={electrolux_header_1} />
          </Grid.Column>
          <Grid.Column width={7}>
            <Image src={electrolux_ideation_1} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%", marginTop: "1em" }}>
          <Grid.Column width={8}>
            <Header as="h3">
              How can we encourage more social involvement and bonding in an
              office environment during lunch time?
            </Header>
            <p>
              Kitchen = the only social environment <br />
              Lunch = the only social time
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h4">Duration</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              Sept 2015 (5 weeks) <br />
              Classroom Project with Electrolux AB, Sweden
            </p>

            <Header as="h4">Advisors</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              Magnus Lindfors (Konstfack University)
              <br />
              Adam Szczepanowski and Leonardo Hatamura (Electrolux AB)
            </p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column width={5}>
            <HeroImage
              src={electrolux_ideation_2}
              imageHeight="15em"
              cornerRadius="1.5%"
            />
          </Grid.Column>
          <Grid.Column width={7}>
            <HeroImage
              src={electrolux_header_2}
              imageHeight="15em"
              cornerRadius="1.5%"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <HeroImage
              src={electrolux_ideation_3}
              imageHeight="15em"
              cornerRadius="1.5%"
            />
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={12}>
            <p>
              This research was conducted at a design consultancy in Stockholm
              targeted at working professionals who either cook or heat
              pre-cooked meals in the office. In today's office environment,
              people wait in the microwave queue for 2 -3 minutes for their food
              to heat, and thereafter miss out on office conversation and end up
              eating alone.
            </p>
            <br />
            <p>
              This experience of waiting for food to heat can become more
              enriching with a low height double sided Microwave Owen with an
              integrated cook-top, allowing dinner plates and cups to be placed
              inside. This would foster social interaction on the lunch table
              and transform the waiting experience into a shared opportunity for
              colleagues to enjoy a meal together.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
