import React from "react";
import { Button } from 'semantic-ui-react';

export type Props = {
  color?: string;
  link: string;
  text: string;
};

export default function LinkButton({ link, text, color }: Props) {
  return (
    <Button
      style={{ marginRight: "1em", marginBottom: "1em", color: color }}
      onClick={() => {
        window.open(link, "_blank", "noopener noreferrer");
      }}
    >
      {text}
    </Button>
  );
}
