import "./App.css";
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

/* cSpell:disable */
import PasswordProtection from "./components/PasswordProtection";

import AboutMe from "./pages/AboutMe";
import AllProjects from "./pages/Homepage";
import Community from "./pages/Community";
import Error404 from "./pages/Error404";
import SandBox from "./pages/SandBox";
import NavBar from "./NavBar";

import DigitalIndia from "./pages/Home/DigitalIndia";
import TruckLite from "./pages/Home/TruckLite";
import HealthPal from "./pages/SandBox/HealthPal";
import TulipInterfaces from "./pages/Home/TulipInterfaces";
import McKinsey from "./pages/Home/McKinsey";

import Appliances from "./pages/Archive/Appliances";
import Sanitation from "./pages/Archive/Sanitation";
import SpecialNeeds from "./pages/Archive/SpecialNeeds";
import CancerCare from "./pages/SandBox/CancerCare";
import MorphingMatter from "./pages/Home/MorphingMatter";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handlePasswordSubmit = (password) => {
    // Validate the password (e.g., check against a predefined password)
    if (password === "McK12345") {
      setIsLoggedIn(true);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<AllProjects />} />
        <Route path="/aboutme" element={<AboutMe />} />
        <Route path="/sandbox" element={<SandBox />} />
        <Route path="/community" element={<Community />} />

        <Route path="/digitalindia" element={<DigitalIndia />} />
        <Route path="/trucklite" element={<TruckLite />} />
        <Route path="/healthpal" element={<HealthPal />} />
        <Route path="/tulip" element={<TulipInterfaces />} />

        <Route path="/appliances" element={<Appliances />} />
        <Route path="/sanitation" element={<Sanitation />} />
        <Route path="/specialneeds" element={<SpecialNeeds />} />
        <Route path="/cancercare" element={<CancerCare />} />
        <Route path="/morphingmatter" element={<MorphingMatter />} />

        {isLoggedIn ? (<Route path="/mckinsey" element={<McKinsey />} />) : (
          <Route path="/mckinsey" element={<PasswordProtection onPasswordSubmit={handlePasswordSubmit}/>} />)
        }

        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default App;
