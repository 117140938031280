import React from "react";
import { Grid, Image, Header } from "semantic-ui-react";
/* cSpell:disable */

import HeroImage from "../../components/HeroImage";

const dustbin_header_1 = "images/SandBox/Sanitation/dustbin_header_1.jpg";
const dustbin_header_2 = "images/SandBox/Sanitation/dustbin_header_2.png";
const dustbin_ideation_1 = "images/SandBox/Sanitation/dustbin_ideation_1.jpg";
const dustbin_ideation_2 = "images/SandBox/Sanitation/dustbin_ideation_2.jpg";
const dustbin_diagram = "images/SandBox/Sanitation/dustbin_diagram.png";

export default function IndianRailways() {
  return (
    <>
      <Grid>
        <Grid.Row centered>
        <Image src={dustbin_header_2} rounded/>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%", marginTop: "1em" }}>
          <Grid.Column width={10}>
            <Header as="h3">
            A large, durable and inviting dustbin for the sleeper compartment
              of the Indian Railways.
            </Header>
            <br />
            <p>
            At the time the research was conducted, very few trains with
              compartments other than first class and 2nd AC had dustbins. The
              project involved studying hygiene patterns of people, the cleaning
              practices of the Indian Railways and potential barriers to introduce a
              dustbin.
            </p>
          </Grid.Column>
          <Grid.Column width={6}>
            <Header as="h4">Duration</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
                January - February 2015 (6 weeks)
                <br />
                National Institute of Design</p>

            <Header as="h4">Advisor</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
            Dr. Praveen Nahar (Dean, NID)
              <br />
              Divya Dave (formerly NID)
            </p>
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}
        
        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h3">Product Details</Header>
            <span>
              Dustbins in the Indian railways are usually placed on the outside wall of the toilet. The proposed dustbin is placed under the washbasin with an opening
              on one side. This side is accessible in the passageway to the restrooms and
             connecting compartments, and facilitates simultaneous usage of the dustbin and washbasin.
              <br />
              <br />
              The bin is lightweight, has an anti-theft locking
              mechanism, and a foot insert at the bottom of the frame to provide
              stability and a counterbalance when the dustbin is emptied by
              pivoting over the horizontal rod on the right-hand side.
            </span>
          </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
          <Grid.Column>
            <Image src={dustbin_diagram} rounded />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={6}>
            <HeroImage
              src={dustbin_ideation_2}
              imageHeight="35vh"
              caption="What is thrown out of the train window"
              cornerRadius="1.5%"
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <HeroImage
              src={dustbin_ideation_1}
              imageHeight="35vh"
              caption="Form and functionality ideation"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <HeroImage
              src={dustbin_header_1}
              imageHeight="35vh"
              caption="Suggested method of use"
              cornerRadius="1.5%"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
