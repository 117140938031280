import React from "react";

export type Props = {
  contentTitle: React.ReactNode;
  subTitle: React.ReactNode;
  contentItems: React.ReactNode;
  ListItems: string[];
};

const ResumeContent = ({
  contentTitle,
  subTitle,
  contentItems,
  ListItems = [],
}: Props) => {
  return (
    <div style={{ marginBottom: "3em" }}>
      <h4 style={{ color: "#333333" }}>{contentTitle}</h4>
      <p style={{ marginTop: "-10px", color: "black" }}>{subTitle}</p>
      <p style={{ marginTop: "-10px", color: "#333333" }}>{contentItems}</p>
      <ul style={{ paddingLeft:"3%"}}>
        {ListItems.map((ListItem) => (
          <li key={ListItem} style={{ marginBottom: "0.3em" }}>
            {ListItem}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ResumeContent;
