import React from "react";
import { Grid, Image, Header } from "semantic-ui-react";
/* cSpell:disable */

const sendriya_header_1 = "images/SandBox/Sanitation/sendriya_header_1.png";
const sendriya_materials = "images/SandBox/Sanitation/sendriya_materials.png";
const sendriya_storyboard = "images/SandBox/Sanitation/sendriya_storyboard.png";

export default function CompostingToilet() {
  return (
    <>
      <Grid>
        <Grid.Row centered>
          <Image src={sendriya_header_1} rounded />
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%", marginTop: "1em" }}>
          <Grid.Column width={8}>
            <Header as="h3">
              Improving sanitation in rural and semi-urban areas with limited
              drainage infrastructure by utilizing human excrete as a resource.
            </Header>
            <br />
            <p>
              Rural and off-the-grid areas do not have proper sewage and
              drainage systems. Coupled with the absence of proper toilets, this
              results in open defecation. Excessively available human excreta
              when converted to compost becomes pathogen-free and can be safely
              applied to farming.
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h4">My Role</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              {" "}
              End-to-end Product Development
            </p>

            <Header as="h4">Recognition</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              <a
                href="http://www.ipindia.nic.in/writereaddata/Portal/IPOJournal/1_1521_1/Part-2_Designs.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                Device for Making Compost, Design Number 286447, Class 09-03
              </a>
              - Registered under the Designs Act, 2000, Government of India.
            </p>

            <Header as="h4">Duration</Header>
            <p style={{ margin: "-2% 0px 2%" }}>March - April 2016 (8 weeks)</p>

            <Header as="h4">Advisor</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
              <a
                href="https://www.ntnu.edu/employees/vikram.s.parmar"
                target="_blank"
                rel="noopener noreferrer"
              >Dr. Vikram Singh Parmar</a>{" "}
              (Formerly NID, Currently Norwegian University of Science and
              Technology)
            </p>
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}
        <Grid.Row style={{ marginBottom: "-1%" }}>
          <Grid.Column width={12}>
          <Header as="h3">Product Details</Header>
            <span>
              The product is a self balancing container on wheels which fits
              under a raised toilet platform. Half of the container fits under
              the platform while the other half remains outside, attached to the
              toilet wall. <br />
              <br />
              Human excreta falls into the container under the platform and
              sawdust, dry hay etc. is added from the outside through a funnel.
              Over a period of a few weeks, this combination gets converted to
              manure.
              <br />
              <br />
              Grey water i.e. urine etc. percolates through the contents of the
              container, collects in a receptacle under the toilet and gets
              absorbed by the soil.
            </span>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
            <Grid.Column>
        <Image src={sendriya_storyboard} style={{marginBottom: "8%"}} /> 

        <Header as="h3">Materials Required</Header>
        <Image src={sendriya_materials} style={{marginBottom: "8%"}}/>
            </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
