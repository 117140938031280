import React from "react";
import { Container, Grid, Header } from "semantic-ui-react";

/* cSpell:enable */

export default function McKinsey() {
  return (
    <Container>
      <Grid stackable>
        <Grid.Row centered>
          <Grid.Column textAlign="center">
            <Header as="h2">
              Generative AI tools to empower McKinsey consultants
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
