import React from "react";

const headerImage = "images/header.png";

export default function SiteHeader() {
  return (
    <>
      <img
        src={headerImage}
        alt="header"
        style={{
          width: "100%",
          height: "auto",
        }}
      />
    </>
  );
}
