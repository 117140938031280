import React from "react";
import { Grid, Image, Header, Container } from "semantic-ui-react";

/* cSpell:disable */

import HeroImage from "../../components/HeroImage";
import HorizontalRule from "../../components/HorizontalRule";

const whirlpool_fridge_exploded =
  "images/Appliances/whirlpool_fridge_exploded.jpg";
const whirlpool_header = "images/Appliances/whirlpool_header.jpg";
const whirlpool_ideation_1 = "images/Appliances/whirlpool_ideation_1.jpg";
const whirlpool_ideation_2 = "images/Appliances/whirlpool_ideation_2.jpg";
const whirlpool_ideation_3 = "images/Appliances/whirlpool_ideation_3.jpg";

const electrolux_header_1 = "images/Appliances/electrolux_header_1.jpg";
const electrolux_header_2 = "images/Appliances/electrolux_header_2.jpg";
const electrolux_ideation_1 = "images/Appliances/electrolux_ideation_1.jpg";
const electrolux_ideation_2 = "images/Appliances/electrolux_ideation_2.jpg";
const electrolux_ideation_3 = "images/Appliances/electrolux_ideation_3.jpg";

const jbl_header = "images/Appliances/jbl_header.jpg";
const jbl_pulse = "images/Appliances/jbl_pulse.jpg";
const jbl_ui = "images/Appliances/jbl_ui.jpg";
const jbl_render_1 = "images/Appliances/jbl_render_1.jpg";
const jbl_render_2 = "images/Appliances/jbl_render_2.jpg";

export default function Appliances() {
  return (
    <Container>
      <Grid stackable columns>
        <Grid.Row centered>
          <Header as="h2">I. Fridge for Indian Millennials</Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Image src={whirlpool_header} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src={whirlpool_fridge_exploded} />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Intro and Role */}
        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={8}>
            <div style={{ marginTop: "1em" }}>
              <p>
                An on-the-go fridge which appeals emotionally to the young
                Indian market segment in the age group of 25-30 years.
                <br />
                <br />
                Design Attributes: Abundance, Culturally Familiar and Ornamental
              </p>
            </div>
          </Grid.Column>

          <Grid.Column width={8}>
            <ul>
              <li>My Role: 3D Modeling, Market Research</li>
              <li>
                Team: Caroline Moureaux-Nery, Aglae Poisson (ENSCI, Paris)
              </li>
              <li>Duration: Jul 2016 (2 weeks)</li>
              <li>Guide: Suresh Sethi, Sumit Singh (Whirlpool Corporation)</li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4} style={{ marginBottom: "0.2em" }}>
            <Image src={whirlpool_ideation_1} />
          </Grid.Column>
          <Grid.Column width={4}>
            <Image src={whirlpool_ideation_3} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src={whirlpool_ideation_2} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <p>
              The target audience is people who are slowly attaining financial
              stability and embody a deep rooted Indian-ness blended with a
              global appeal. They live alone, in shared spaces or with a family
              in a privately owned place. The reason for making the fridge
              modular and stackable is that single fridge owners can make a
              bigger fridge when they start living together.
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <p>
              The fridge can be mounted on the wall or placed on a counter top.
              The topmost tray has cutouts for keeping bowls without slipping
              since the Indian cuisine comprises a lot of condiments with meals.
              The fridge is modular and the partition wall and freezer unit can
              be removed for storing a large vessel, water bottles, etc.
            </p>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}

        <Grid.Row centered>
          <Header as="h2">II. Microwave for a Shared Kitchen</Header>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={9}>
            <Image src={electrolux_header_1} />
          </Grid.Column>
          <Grid.Column width={7}>
            <Image src={electrolux_ideation_1} />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Intro and Role */}
        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={8}>
            <div style={{ marginTop: "1em" }}>
              <p>
                How can we encourage more social involvement and bonding in an
                office environment during lunch time? <br />
                <br />
                Kitchen = the only social environment <br />
                Lunch = the only social time
              </p>
            </div>
          </Grid.Column>

          <Grid.Column width={8}>
            <ul>
              <li>My Role: end-to-end Product Development Process</li>
              <li>Duration: Sept 2015 (5 weeks)</li>
              <li>
                Guides: Magnus Lindfors(Konstfack University), Adam
                Szczepanowski and Leonardo Hatamura (Electrolux AB)
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "2%" }}>
          <Grid.Column width={5}>
            <HeroImage src={electrolux_ideation_2} imageHeight="15em" />
          </Grid.Column>
          <Grid.Column width={7}>
            <HeroImage src={electrolux_header_2} imageHeight="15em" />
          </Grid.Column>
          <Grid.Column width={4}>
            <HeroImage src={electrolux_ideation_3} imageHeight="15em" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <span>
              In current office scenarios, people miss out on office
              conversation and end up eating alone while waiting for 2 -3
              minutes for their food to heat in front of the microwave or
              standing in the microwave queue. The research for this was
              conducted at a design consultancy in Stockholm and targeted at
              working professionals who either cook in office or heat pre-cooked
              food in the office microwave.
            </span>
          </Grid.Column>
          <Grid.Column width={8}>
            <span>
              This experience of waiting for food to heat can become more
              enriching with a low height double sided Microwave Owen (MWO) with
              an integrated cook-top in which dinner plates and cups can be
              placed inside. This would facilitate conversation on a lunch table
              and people would be able to eat with their friends/ teammates in
              office.
            </span>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}

        <Grid.Row centered>
          <Header as="h2">III. Headphone Concept for JBL</Header>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={9}>
            <Image
              src={jbl_header}
              size="big"
              style={{ height: "18em", objectFit: "cover", width: "auto" }}
            />
          </Grid.Column>

          <Grid.Column width={7}>
            <Image
              src={jbl_render_2}
              size="big"
              style={{ height: "18em", objectFit: "cover", width: "auto" }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <p>
              <br />
              The inspiration for this concept came when I saw and heard music
              being played on the JBL pulse and found the changing colours super
              fascinating.
            </p>
          </Grid.Column>

          <Grid.Column width={5}>
            <HeroImage src={jbl_pulse} imageHeight="11em" />
          </Grid.Column>

          <Grid.Column width={3}>
            <HeroImage src={jbl_ui} imageHeight="11em" />
          </Grid.Column>

          <Grid.Column width={4}>
            <HeroImage src={jbl_render_1} imageHeight="11em" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={12}>
            <p>
              The headphone area over the right ear has touch controls and can
              be used while wearing. Since the previous - next buttons and
              colour changing and volume controls will be reversed if worn over
              the left ear, it is ideal to wear the headphones with the control
              area over the right ear. They glow at night (or in the day) and
              the colour of the light can be customized according to one's
              clothes and mood. <br />
              <br />* not sponsored by JBL.
            </p>
          </Grid.Column>

          <Grid.Column width={4} textAlign="center" verticalAlign="middle">
            <h3>express to impress.</h3>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
