import { useState } from "react";
import { Modal } from 'semantic-ui-react';

export type Props = {
  triggerCTA: React.ReactNode;
  modalHeader: string;
  modalBody: React.ReactNode;
};

const ModalScrolling = ({ triggerCTA, modalHeader, modalBody }: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      closeIcon
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      trigger={triggerCTA}
    >
      <Modal.Header>{modalHeader}</Modal.Header>
      <Modal.Content>
        {modalBody}
      </Modal.Content>
    </Modal>
  );
};

export default ModalScrolling;
