import React, { useRef } from "react";
import {
  Button,
  Container,
  Embed,
  Grid,
  Header,
  Icon,
  Image,
} from "semantic-ui-react";

/* cSpell:enable */
import LinkButton from "../../components/LinkButton";
import HorizontalRule from "../../components/HorizontalRule";
import CenteredImage from "../../components/CenteredImage";
import SiteFooter from "../../components/SiteFooter";
import ImageCarousel from "../../components/ImageCarousel";

// images
const tulip_header = "images/Tulip/tulip_hero_3.png";
const workspaces_hero = "images/Tulip/workspaces_hero.jpeg";
const workspaces_1="images/Tulip/workspaces_1.png";
const workspaces_2="images/Tulip/workspaces_2.png";
const workspaces_3="images/Tulip/workspaces_3.png";
const rbac_1="./images/Tulip/rbac_1.png";
const rbac_2="./images/Tulip/rbac_2.png";
const rbac_3="./images/Tulip/rbac_3.png";
const ws_stats_1="./images/Tulip/ws_stats_1.png";
const ws_stats_2="./images/Tulip/ws_stats_2.png";

//machine monitoring
const mm_stats_1="./images/Tulip/mm_stats_1.png";
const mm_stats_2="./images/Tulip/mm_stats_2.png";
const mm_stats_3="./images/Tulip/mm_stats_3.png";
const mm_stats_4="./images/Tulip/mm_stats_4.png";
const mm_twitter = "images/Tulip/mm_twitter.png";
const mm_connect = "images/Tulip/mm_connect.png";
// const mm_relation = "images/Tulip/mm_relation.png";
// const mm_oldnav = "images/Tulip/mm_oldnav.png";
const mm_attr_mapping_old = "images/Tulip/mm-attr-mapping-old.png";
const mm_attr_mapping_new = "images/Tulip/mm-attr-mapping-new.png";
const mm_widget_new="images/Tulip/mm_widget.png";
const mm_widget_tulip="images/Tulip/mm_widget_tulip.png";

//illustrations
const invitation = "images/Tulip/emptyState_invitation.svg";
const machineAttribute = "images/Tulip/emptyState_machineAttribute.svg";
const machineDowntimeReason ="images/Tulip/emptyState_machineDowntimeReason.svg";
const machineState = "images/Tulip/emptyState_machineState.svg";
const noSearchResults = "images/Tulip/emptyState_noSearchResults.svg";
const trigger = "images/Tulip/emptyState_trigger.svg";
const ipAllowlist = "images/Tulip/ipAllowlist.svg";
const emptyStatePage = "images/Tulip/emptyState_page.png";

//onboarding
const onBoarding_step_1 = "images/Tulip/onboarding_step_1.png";
const onBoarding_step_2 = "images/Tulip/onboarding_step_2.png";
const onBoarding_step_3 = "images/Tulip/onboarding_step_3.png";
const onBoarding_step_4 = "images/Tulip/onboarding_step_4.png";

// IP allowlist
const IP_Allowlist = "images/Tulip/IP_Allowlist.png";
const systemStatus = "images/Tulip/allowlist_SystemStatus.png";

export default function TulipInterfaces() {
  const backRef = useRef(null);

  return (
    <>
      <Container>
        <Grid stackable>
          <Grid.Row centered>
            <Grid.Column width={15} textAlign="center">
              <div ref={backRef}>
                <Header as="h2">Tulip Industrial DataOps</Header>
              </div>
              <img src={tulip_header} alt="industrial DataOps at Tulip" loading="lazy" />
            </Grid.Column>
          </Grid.Row>

          {/* ________________________________________________________________________________ */}
          {/* Intro and Role */}

          <Grid.Row style={{ margin: "2% 0px" }}>
            <Grid.Column width={10}>
              <Header as="h3" style={{ marginRight: "20%" }}>
                Scale the Tulip platform to manage the growing needs of
                enterprise customers and unify fragmented machine monitoring
                experiences.
              </Header>
              <p style={{ marginBottom: "3%", fontSize: "16px" }}>
                <a href="https://tulip.co/" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>Tulip</u></a>{" "}
                is an IIoT native frontline operations platform that 
                digitally transform manufacturing operations connecting 
                people, machines, and data across the shop floor to 
                enable higher productivity, quality, and efficiency in
                manufacturing processes. As of 2023, The platform was used in 20
                countries across 35 industries, ~50k applications
                created per year. 
              </p>

              <Header as="h4">Challenges</Header>
              <p style={{ margin: "-1.5% 0px 3%", fontSize: "16px"}}>
                <ul>
                  <li>The machine monitoring process was complex in Tulip and users rather not touch it once setup</li>
                  <li>Manufacturing systems are siloed with each site building its own system, making it difficult to share data and resources</li>
                </ul>
              </p>
            </Grid.Column>

            <Grid.Column width={1} />

            <Grid.Column width={5}>
              <Header as="h4">My Role</Header>
              <p style={{ margin: "-1.5% 0px 3%", fontSize: "16px"}}>
                Design: I owned the design of key enterprise features such 
                as machine monitoring, sharing data across multi-site operations, 
                platform settings, etc. and led user research with clients.<br /><br />
                
                Skills: Enterprise Product Design, Design Systems
              </p>

              <Header as="h4">Team</Header>
              <p style={{ margin: "-1.5% 0px 3%", fontSize: "16px" }}>
                1 Product Manager, 3 Developers
              </p>

              <Header as="h4">Duration</Header>
              <p style={{ margin: "-1.5% 0px 3%", fontSize: "16px"}}>Projects done over 1.5 years <br />
              Mar 2020 - Sept 2021</p>
            </Grid.Column>
          </Grid.Row>

          <HorizontalRule size="Large" />
        </Grid>

        <Grid stackable>
          {/* ________________________________________________________________________________ */}
          {/* Machine Monitoring */}
          <Grid.Row>
            <Grid.Column width={7}>
              <Header as="h2">Machine Monitoring Data Pipeline</Header>
              <Header as="h5" style={{ marginTop: "-0.5em", color: "#545454"}}>
                I led the design effort to overhaul IIoT machine monitoring and connectivity experiences
                for industry 4.0 to make hardware data human readable.
              </Header>
              <br />
              <p style={{fontSize: "10px"}}>Image and information credits: Tulip Interfaces, Inc.</p>
            </Grid.Column>
            <Grid.Column width={2} />
          </Grid.Row>

          {/* ______________________________________________________________________________________ */}
          <Grid.Row>
                <Grid.Column width ={4}>
                  <Image src={mm_stats_1} rounded />
                    <p style={{marginTop: "3%"}}>A chocolate manufacturer brought analog machines online and created real time process visibility in its factory using Tulip, increasing production by 30%.</p>
                </Grid.Column>
            
                <Grid.Column width ={4}>
                    <Image src={mm_stats_2} rounded />
                    <p style={{marginTop: "3%"}}>A frozen food manufacturer improved production visibility and increased throughput by moving to digital machine monitoring in Tulip.</p>
                </Grid.Column>

                <Grid.Column width ={4}>
                  <Image src={mm_stats_4} rounded bordered />
                  <p style={{marginTop: "3%"}}>DMG MORI, the world's largest CNC manufacturer used Tulip to digitize their spindle assembly line.</p>
                </Grid.Column>

                <Grid.Column width ={4}>
                    <Image src={mm_stats_3} rounded />
                    <p style={{marginTop: "3%"}}>A sinter metal parts maker for the aerospace industry reduced machine downtime, and increased efficiency by 130% in less than 3 months.</p>
                </Grid.Column>
            </Grid.Row>
            <HorizontalRule size="Small" />

           {/* ______________________________________________________________________________________ */}

          {/* MM Connect */}
          <Grid.Row>
            <Grid.Column width={5}>
              <p style={{ marginTop: "15%", color: "#545454"}}><b>STEP 1</b></p>
              <Header as="h3" style={{ marginTop: "-0.5em"}}>Connect data sources</Header>
              <Header as="h5" style={{ marginTop: "-0.5em", marginBottom: "1.5em", color: "#545454"}}>
                Natively connect protocols such as OPCUA, Tulip API, and legacy equipments to Tulip to capture information from machine and device signals.</Header>
              {/* <img src={mm_relation} alt="mental model of machine monitoring"/> */}
            </Grid.Column>
            <Grid.Column width={11}><img src={mm_connect} alt="redesigned machine navigation"/></Grid.Column>
          </Grid.Row>
          
          <Grid.Row style={{marginTop: "-1.5em"}}>
            <Grid.Column width={5} />
            {/* <Grid.Column width={6}><p style={{fontSize: "16px"}}><b>Mental Model - </b>Multiple machines can belong to a type and global attributes defined on the type level are individually mapped to signals from multiple data sources.</p></Grid.Column> */}
            <Grid.Column width={5}><p style={{fontSize: "16px"}}><b>Before 👎🏼 - </b>Unclear relationship between machines, attributes and data sources.</p></Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={5}><p style={{fontSize: "16px"}}><b>After 👍🏼 - </b>Relation between different machine monitoring entities made
                clear through an updated navigation and logical groupings.</p></Grid.Column>
            </Grid.Row>

            <HorizontalRule size="Small" />

          {/* MM Configure */}
          <Grid.Row style={{ marginBottom: "4%" }}>
            <Grid.Column width={6} style={{marginTop:"4%"}}>
              <p style={{ marginTop: "12%", color: "#545454"}}><b>STEP 2</b></p>
              <Header as="h3" style={{ marginTop: "-0.5em"}}>Configure mappings</Header>
              <Header as="h4" style={{ marginTop: "-0.5em", marginBottom: "1.5em", color: "#545454"}}>
              Standardize attributes across sources so data can speak to everyone–from operators to supervisors to management.
              </Header>
              <LinkButton link="https://support.tulip.co/docs/how-to-add-and-configure-machines" text="How to configure mappings"/>
              {/* <Divider hidden />

              <div style={{ backgroundColor: "#F0FAB4", borderRadius: "0.3em 1em 1em 1em", padding: "0.8em"}}>
                <p> 😕 “I am unable to see how the data is structured and take further decisions on it.” </p>
              </div> */}
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={7}>
              <img src={mm_attr_mapping_old} alt="redesigned attribute mapping experience" style={{ marginBottom: "1.5em" }} loading="lazy"/>
              <p style={{fontSize: "16px"}}><b>Before 👎🏼 -</b> Unclear mental model of signal mapping, leading to difficulty 
              in decision making. Poor visibility and navigation of the OPC-UA data source, exacerbating the problem. 
              Lacks scalability for multi-data source mapping.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column>
              <img src={mm_attr_mapping_new} alt="redesigned attribute mapping experience"/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <p style={{fontSize: "16px", marginTop: "-4%"}}><b>After 👍🏼 - </b>Clearly expressed mapping of multiple data signals to attributes. OPCUA data visualized as a tree to display node hierarchy.</p>
            </Grid.Column>
          </Grid.Row>
          <HorizontalRule size="Small" />

          {/* ________________________ */}

          <Grid.Row style={{marginBottom:"6%"}}>
            <Grid.Column width={5} style={{marginTop:"4%"}}>
              <p style={{ marginTop: "12%", color: "#545454"}}><b>STEP 3</b></p>
              <Header as="h3" style={{ marginTop: "-0.5em"}}>Contextualize</Header>
              <Header as="h5" style={{ marginTop: "-0.5em", color: "#545454"}}>Visualize machine data through widgets and dashboard displays to drive business outcomes</Header>
            </Grid.Column>
            <Grid.Column width={2} />
            <Grid.Column width={7}>
              <Image src={mm_widget_tulip} alt="tulip machine widget demo"/>
              <p style={{fontSize: "16px", marginTop: "0.5em"}}>I designed multiple widgets that capture and visualize machine data</p>
              </Grid.Column>
          </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <img src={mm_widget_new} alt="machine widget editing experience" />
            <p style={{fontSize: "16px", marginTop: "0.5em"}}>Expanded machine widgets library for web and touch interactions, giving users granular controls to display data from digital and analog signals.</p>
          </Grid.Column>
        </Grid.Row>
          <HorizontalRule size="Large" />

          {/* ________________________________________________________________________________ */}
          {/* Workspaces */}

          <Grid.Row centered>
            <Grid.Column width={6}>
              <Header as="h2">Workspaces</Header>
              <Header as="h4" style={{marginTop: "-0.5em", color: "#545454"}}>
                I designed and launched Workspaces in collaboration with the Product Manager, and engineering team. 
                Workspaces make it easy to access data and share common resources across multi-site 
                operations, and get them up and running quickly, reducing barriers to growth and scaling. 
              </Header>
              <br />
              <LinkButton
                link="https://tulip.co/blog/introducing-workspaces-in-tulip/"
                text="Read More"
              />
              <LinkButton
                link="https://tulip.co/press/tulip-introduces-workspaces/"
                text="Press Release"
              />
              <br />
            </Grid.Column>
            <Grid.Column width={10}>
              <Embed
                id="0b-HSYuv-Dk"
                source="youtube"
                style={{ borderRadius: "0.5em" }}
                placeholder={workspaces_hero}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{marginBottom: "8%"}}>
            <Grid.Column width={4}>
              <Image src={ws_stats_1} bordered rounded as="a" href='https://tulip.co/blog/scaling-operations-with-tulips-multisite-capabilities/' target='_blank'/>
            </Grid.Column>

            <Grid.Column width={4}>
              <iframe
                src="https://www.linkedin.com/embed/feed/update/urn:li:share:6874744782619480065"
                width="100%"
                frameBorder="0"
                allowFullScreen=""
                title="Embedded post"
                style={{ borderRadius: "0.6vh", border: "1px solid #E5E5E5", aspectRatio: "1 / 1" }}
              ></iframe>
            </Grid.Column>

            <Grid.Column width={4}>
              <iframe
                src="https://www.linkedin.com/embed/feed/update/urn:li:share:6889258250475319296"
                width="100%"
                frameBorder="0"
                allowFullScreen=""
                title="Embedded post"
                style={{ borderRadius: "0.6vh", border: "1px solid #E5E5E5", aspectRatio: "1 / 1" }}
              ></iframe>
            </Grid.Column>

            <Grid.Column width={4}>
              <Image src={ws_stats_2} bordered rounded as="a" href="https://youtu.be/W8aUZInqn5E?si=81QtIzYCgrTrnK87" target="_blank"/>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{marginBottom: "8%"}}>
            <Grid.Column width={12}>
              <Image src={workspaces_1} />
            </Grid.Column>
            <Grid.Column width={4} style={{marginTop: "6%"}}>
              <Header as="h3">Centralized account and workspace settings</Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{marginBottom: "4%"}}>
            <Grid.Column width={6} style={{marginTop: "6%"}}>
              <Header as="h3">Granular control over users and permissions</Header>
              <Header as="h4" style={{marginTop: "-0.5em", color: "#545454"}}>
                Ensures enterprise data segregation and security, setting up facilities to succeed.</Header>
            </Grid.Column>
            <Grid.Column width={10}>
              <Image src={workspaces_2} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column><Header as="h3">Role-based access control</Header></Grid.Column>
          </Grid.Row>
          <Grid.Row style={{marginBottom: "4%"}}>
            <Grid.Column width={16}><Image src={workspaces_3} /></Grid.Column>
          </Grid.Row>
{/* 
          <Grid.Row style={{marginBottom: "4%", backgroundColor: "#E2F2F4", borderRadius: "2vh"}} columns={3}>
            <Grid.Column fluid>
              <Image src={rbac_1} style={{ height: '30vh', width: 'auto' }} />
            </Grid.Column>
          <Grid.Column fluid>
            <Image src={rbac_2} style={{ height: '30vh', width: 'auto' }} />
          </Grid.Column>
          <Grid.Column fluid>
            <Image src={rbac_3} style={{ height: '30vh', width: 'auto' }} />
          </Grid.Column>
          </Grid.Row> */}

          <HorizontalRule size="Large" />

          {/* ________________________________________________________________________________ */}
          {/* IP Allowlisting */}

          <Grid.Row>
            <Grid.Column width={12}>
              <Header as="h2">Allow selected IP addresses to access the system</Header>
              <LinkButton
                link="https://support.tulip.co/docs/configuring-your-ip-allowlist"
                text="How to setup an IP Allowlist"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered style={{ marginBottom: "8%" }}>
            <Grid.Column width={15}>
              <Image src={IP_Allowlist} />
              <Image
                src={ipAllowlist}
                style={{
                  position: "absolute",
                  top: "75%",
                  left: "94%",
                  objectFit: "cover",
                  width: "auto",
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: "8em",
                  zIndex: 1,
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h4">Visibility of the System Status enables users 
                to make better decisions and offers a sense of control</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}><Image src={systemStatus} /></Grid.Column>
          </Grid.Row>

          <HorizontalRule size="Large" />

          {/* ________________________________________________________________________________ */}
          {/* Onboarding experience */}

          <Grid.Row>
            <Grid.Column width="4">
              <Header as="h2" style={{ marginTop: "1.5em" }}>
                Tulip Onboarding
              </Header>
              <Header as="h5" style={{ marginTop: "-0.5em", color: "#545454"}}>
                Bring new users into Tulip, and understand their goals to cater
                to their experience accordingly. 
              </Header>
            </Grid.Column>

            <Grid.Column width="12">
              <ImageCarousel
                showDots={true}
                sliderContent={[
                  <Image key={1} src={onBoarding_step_1} rounded bordered />,
                  <Image key={2} src={onBoarding_step_2} rounded bordered />,
                  <Image key={3} src={onBoarding_step_3} rounded bordered />,
                  <Image key={4} src={onBoarding_step_4} rounded bordered />,
                ]}
              />
            </Grid.Column>
          </Grid.Row>

          <HorizontalRule size="Large" />

          {/* ________________________________________________________________________________ */}
          {/* Illustrations and Empty States */}

          <Grid.Row style={{ marginBottom: "5%" }}>
            <Grid.Column width={5}>
              <Header as="h2">Illustrations</Header>
              <Header as="h5" style={{ marginTop: "-0.5em", color: "#545454"}}>
                Infuse personality into the Tulip platform through illustrations and empty states that make users aware of the system status.
              </Header>
            </Grid.Column>
            <Grid.Column width={11}>
              <Image src={emptyStatePage} loading="lazy"/>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid doubling columns={4}>
          <Grid.Row centered style={{ marginBottom: "2%" }}>
            <Grid.Column width={4}>
              <CenteredImage
                src={noSearchResults}
                displayImageAlt="empty state for no search results"
                imageCaption="No Search Results/ Error 404"
                imageHeight="12em"
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <CenteredImage
                src={machineState}
                displayImageAlt="empty state for machine state"
                imageCaption="Machine State"
                imageHeight="12em"
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <CenteredImage
                src={machineAttribute}
                displayImageAlt="empty state for machine attribute"
                imageCaption="Machine Attribute"
                imageHeight="12em"
              />
            </Grid.Column>
          </Grid.Row>

          {/* __________________________ */}

          <Grid.Row centered>

          <Grid.Column width={4}>
              <CenteredImage
                src={machineDowntimeReason}
                displayImageAlt="empty state for machine downtime reason"
                imageCaption="Machine Downtime"
                imageHeight="12em"
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <CenteredImage
                src={trigger}
                displayImageAlt="illustration for triggers"
                imageCaption="Triggers"
                imageHeight="12em"
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <CenteredImage
                src={invitation}
                displayImageAlt="illustration for invitation sent"
                imageCaption="Invitation Sent"
                imageHeight="12em"
              />
            </Grid.Column>

            <Grid.Column width={8} />
          </Grid.Row>

          {/* ________________________________________________________________________________ */}

          <Grid.Row centered style={{ margin: "8% 0px" }}>
            <Grid.Column verticalAlign="middle">
              <Button
                onClick={() => {
                  backRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start",
                  });
                }}
              >
                Back to Top
                <Icon
                  name="caret up"
                  alt="caret up"
                  style={{ marginLeft: "0.5em", marginRight: "-0.5em" }}
                />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <SiteFooter />
    </>
  );
}
