import { Grid, Image, Header, Embed } from "semantic-ui-react";

const hyperloop_cover = "./images/SandBox/Hyperloop/hyperloop_cover.jpg";
const hyperloop_vision = "./images/SandBox/Hyperloop/hyperloop_vision.png";
const hyperloop_flowchart = "./images/SandBox/Hyperloop/hyperloop_flowchart.jpeg";
const hyperloop_ui = "./images/SandBox/Hyperloop/hyperloop_ui.jpg";
const hyperloop_video="./images/SandBox/Hyperloop/hyperloop_video.png";
const hyperloop_lecture="./images/SandBox/Hyperloop/hyperloop_lecture.png";

export default function Hyperloop() {
  return (
    <>
      <Grid>
        <Grid.Row centered>
        <Image src={hyperloop_cover} rounded/>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%", marginTop: "1em" }}>
          <Grid.Column width={10}>
            <Header as="h3">How might we approach building the Hyperloop from a systems thinking 
            perspective to make it safe, feasible, and scalable as a mode of transport.
            </Header>
            <br />
            <p style={{marginTop: "-2%"}}>Designed and manufactured India’s 1st magnetic levitation powered Hyperloop pod which 
                competed at the SpaceX Competition Weekend 2 in 2017.</p>
          </Grid.Column>
          <Grid.Column width={6}>
          <Header as="h4">My Role</Header>
            <p style={{ margin: "-2% 0px 2%" }}>
            Hired and managed a design team of 6 and led designs for the GUI of the pod’s control panel</p>
            
            <Header as="h4">Duration</Header>
            <p style={{ margin: "-2% 0px 2%" }}>Feb 2017 - Sep 2017 (7 months, part time)</p>
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h3">Designing the Pod's control panel</Header>
            <span>
            Armed with an industrial design degree, some service design experience, excitement for the success of the project,
            and a healthy dose of confidence, designing the pod's control panel was my first foray into building a human-machine 
            interface that would:<ul>
                <li>control a magnetically-levitating high-speed moving object </li>
                <li>check the pod's yaw, pitch, and roll (i.e. alignment on the X, Y, Z coordinates)</li>
                <li>monitor battery health</li>
                <li>inform the pod controller of the system status</li>
            </ul>
            Although this interface looks a bit slapped together [yes it is from 2017], it got me really interested in continuing 
            to design for such interactions, where each coordinate, and each second is of utmost importance. 
            </span>
          </Grid.Column>
        </Grid.Row>
        
        <Grid.Row centered style={{marginBottom: "8%"}}>
          <Grid.Column width={16}>
            <Image src={hyperloop_ui} rounded bordered/>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h3">Founding Designer of the team</Header>
            <span>
            When I heard from a friend that they are building a Hyperloop Pod over my college break 
            (I vividly remember it as a conversation while soaking the winter sun on 
            the terrace of a building we were not supposed to be on), I immediately jumped on the bandwagon, 
            and pitched to the founder how design can bring value to what they are building. There was synergy, 
            and I was all aboard! <br /><br />
            I expanded the design team - 4 did an intense 3 months systems study on the feasibility of the Hyperloop 
            India corridor, and 2 were graphic designers. This involved resume shortlisting, interviewing, and thereafter
            leading the team. 
            </span>
          </Grid.Column>
        </Grid.Row>
        
        <Grid.Row centered style={{marginBottom: "8%"}}>
          <Grid.Column width={16}>
            <Image src={hyperloop_vision} rounded bordered/>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h3">Team Achievements</Header>
            <span>
            The OrcaPod - India’s first magnetic levitation system prototype, was the only entry from India
            to qualify and race at the SpaceX Hyperloop Competition 2017 in Hawthorne, CA. It stood out as one of most promising 
            prototype with a scalable payload and was lauded for having the finest electrical and under-structure assembly by SpaceX engineers. 
            <br /><br />
            
            The team comprising 52 students from the top engineering, design, and business schools of India - 
            BITS, NID, IIM Ahmedabad, etc. created India's first Hyperloop corridor study, winning the Hyperloop One Global Challenge, and 
            brought together the national stakeholder ecosystem to accelerate this in India.
            </span>
          </Grid.Column>
          </Grid.Row>
          <Grid.Row centered style={{marginBottom: "8%"}}>
          <Grid.Column width={16}>
            <Image src={hyperloop_flowchart} rounded />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h3">Media coverage and interest</Header>
            <span>
            We garnered a lot of media coverage in 2017-18 in India's leading newspapers, which can
            be found through a simple search. Hyperloop One has shut down, since there are a lot of challenges
            in building Hyperloop that cannot be justified from a business or economic perspective. 
            Here are few tidbits to give a quick overview and more recent engagements. 
            </span>
        </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{marginBottom: "8%"}}>
            <Grid.Column width={8}>
            <Embed
                id="R2Eb02S82cc"
                source="youtube"
                style={{ borderRadius: "0.5em" }}
                aspectRatio='16:9'
                placeholder={hyperloop_video}
              />
              <p style={{marginTop: "3%"}}>2017 - Hyperloop India overview</p>
            </Grid.Column>
            <Grid.Column width={8}>
            <Image src={hyperloop_lecture} rounded/>
            <p style={{marginTop: "3%"}}>2022 - Invited as a guest lecturer by UNC Charlotte to discuss the vision 
            and feasibility of Hyperloop</p>
            </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
