import { Grid, Image, Header, Container, Embed } from "semantic-ui-react";

/* cSpell:disable */

import HorizontalRule from "../../components/HorizontalRule";

const amm_infographic = "images/Special Needs/amm_infographic.jpg";
const amm_solution_1 = "images/Special Needs/amm_solution_1.png";
const amm_solution_2 = "images/Special Needs/amm_solution_2.png";

const mlc_header_2 = "images/Special Needs/mlc_header_2.jpg";
const mlc_video_1 = "images/Special Needs/mlc_video_1.jpg";
const mlc_video_2 = "images/Special Needs/mlc_video_2.jpeg";

// ________________________________________________________________________________

export default function SpecialNeeds() {
  return (
    <Container>
      <Grid stackable columns>
        <Grid.Row centered>
          <Header as="h2">I. Vibro-tactile Aid for the Deaf-Blind</Header>
          <Image src={mlc_header_2} />
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={8}>
            <p style={{ marginTop: "1em" }}>
              <b>
                A vibro-tactile aid for the deaf-blind, deaf and blind people to
                make them experience time through music.{" "}
              </b>
              <br />
              <br />
              (Vibro-tactile = relating to the perception of vibration through
              touch)
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <ul>
              <li>My Role: Industrial Design Research Intern</li>
              <li>Duration: Sept 2015 (4 weeks)</li>
              <li>
                Guides: Elsa Vaara (Mobile Life Centre, Stockholm), Cheryl Akner
                Koler (Konstfack University)
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "2%" }}>
          <Grid.Column width={8}>
            <Header as="h3">User</Header>
            <span>
              We perceive time through change. The brain of deaf-blind, deaf and
              blind people rewires itself to boost the remaining senses, hence
              they can feel vibrations much more strongly. We changed the speed,
              direction and intensity of music to make users feel time.
            </span>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3">Vibro-Tactile Stimuli</Header>
            <span>
              Vibro-tactile stimuli at specific intervals give a sense of beats,
              and beats are temporal. We conducted experiments using a bass pump
              and small vibrators to understand how music vibrations transmit
              through different materials.
            </span>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Embed id="151751092" placeholder={mlc_video_1} source="vimeo" />
            <p style={{ marginTop: "1em" }}>
              We connected the dots between seemingly unrelated entities - time,
              sound and vibrations. This video shows our experiments, ideation,
              user studies and initial prototype testing.
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Embed id="151750505" placeholder={mlc_video_2} source="vimeo" />
            <p style={{ marginTop: "1em" }}>
              The insights from conducting experiments were used to create a
              vibro-tactile scarf cum sweater. Apart from the target audience,
              the scarf can be used by elderly and individuals who seek an
              enhanced music experience.
            </p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <HorizontalRule size="Large" />

        <Grid.Row centered>
          <Header as="h2">
            II. Economic Upliftment of Intellectually-Disabled Young Adults
          </Header>
          <Image src={amm_infographic} />
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={8}>
            <p style={{ marginTop: "1em" }}>
              <b>
                Taking advantage of their abilities, how might we increase the
                economic productivity of young adults who are intellectually
                disabled?{" "}
              </b>
              (15 yrs and above) <br />
              <br />
              1,505,624 individuals in India (0.12% of the total population)
              have arrested or incomplete development of the mind, specially
              characterized by sub-normality of intelligence.
              <br />
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <ul>
              <li>Duration: Oct 2016 (3 weeks)</li>
              <li>Guides: Gayatri Menon(National Institute of Design)</li>
              <li>
                In collaboration with Apang Manav Mandal, Ahmedabad, India
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "-1%" }}>
          <Grid.Column>
            <Header as="h3">Value Creation</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <span>
              The motivation was to harness the potential of the intellectually
              disabled in a better way and monetize the spirit of philanthropy
              to make a product that is unique and differentiated compared to
              mass-manufactured goods. Workshops were conducted with young
              adults who have mild and moderate intellectual disability to
              understand their motor and cognitive levels.
            </span>
          </Grid.Column>
          <Grid.Column width={8}>
            <span>The main inferences were: </span>
            <ul>
              <li>
                Instructions have to be given repetitively. What might be
                intuitive to a person with an acceptable cognitive level, is not
                appropriate for a person with a lower level of cognition.
              </li>
              <li>
                An activity which is repetitive, has minimum number of steps and
                is easy to follow is thus viable.
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={8}>
            <Image src={amm_solution_2} />
            <Header as="h3">Clip</Header>
            <span>
              Holds wires of different lengths in place. Simple knots can be
              made over earphones, USB cables, headphone wires etc. to make them
              tangle free. Each piece is unique,handcrafted and customized.
            </span>
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src={amm_solution_1} />
            <Header as="h3">Hand Printed Box</Header>
            <span>
              A block printed packaging box. When small blocks are used to print
              in a circular symmetry, minor imperfections in alignment add up
              and render a product beautiful, setting it apart from mass
              manufactured goods.
            </span>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
      </Grid>
    </Container>
  );
}
