import React from "react";
import { useRef } from "react";

import {
  Button,
  Container,
  Grid,
  Header,
  Image,
  Icon,
  Table,
} from "semantic-ui-react";
import HeroImage from "../../components/HeroImage";
import ReadMoreLink from "../../components/ReadMoreLink";
import ContentHeader from "../../components/ContentHeader";
import HorizontalRule from "../../components/HorizontalRule";
import SiteFooter from "../../components/SiteFooter";

/* cSpell:disable */

const truck_header = "images/TruckLite/truck_header.png";
const truck_journey = "images/TruckLite/truck_journey.png";
const truck_login = "images/TruckLite/truck_login.png";

const truck_csa_1 = "images/TruckLite/truck_csa_1.jpg";
const truck_csa_2 = "images/TruckLite/truck_csa_2.jpg";

const truck_licenseLinking = "images/TruckLite/truck_licenseLinking.png";
const truck_CSADeconstructed = "images/TruckLite/truck_CSADeconstructed.png";
const truck_CSAFlow = "images/TruckLite/Truck_CSAFlow.png";

const truck_notifications = "images/TruckLite/truck_notifications.png";
const truck_health1 = "images/TruckLite/truck_health1.png";
const truck_health2 = "images/TruckLite/truck_health2.png";

const truck_CSACheck = "images/TruckLite/truck_CSACheck.png";
const truck_healthHistory = "images/TruckLite/truck_healthHistory.png";
const truck_prePickup = "images/TruckLite/truck_prePickUp.png";
const truck_notificationLevels =
  "images/TruckLite/truck_notificationLevels.png";

const truck_sensorRead = "images/TruckLite/truck_sensorRead.png";
const truck_relySensor = "images/TruckLite/truck_relySensor.png";
const truck_holoLight = "images/TruckLite/truck_holoLight.png";
const truck_CSATablet = "images/TruckLite/truck_CSATablet.png";

const truck_process = "images/TruckLite/truck_process.png";
const truck_Research1 = "images/TruckLite/Truck_Research1.jpg";
const truck_Research2 = "images/TruckLite/Truck_Research2.png";
const truck_Research3 = "images/TruckLite/Truck_Research3.png";
const truck_concept_1 = "images/TruckLite/truck_concept_1.png";
const truck_concept_2 = "images/TruckLite/truck_concept_2.png";
const truck_BuildAThon_1 = "images/TruckLite/truck_BuildAThon_1.png";
const truck_BuildAThon_2 = "images/TruckLite/truck_BuildAThon_2.png";
const truck_PUGH = "images/TruckLite/truck_PUGH.jpg";
const truck_ServiceBlueprint = "images/TruckLite/truck_ServiceBlueprint.png";
const truck_ValueFlow = "images/TruckLite/truck_ValueFlow.png";
const truck_UxIteration_1 = "images/TruckLite/truck_UxIteration_1.png";
const truck_UxIteration_2 = "images/TruckLite/truck_UxIteration_2.png";

export function CircleWith({ text }) {
  return (
    <div
      style={{
        display: "flex",
        width: "9em",
        height: "9em",
        backgroundColor: "#FBC743",
        borderRadius: "50%",
      }}
    >
      <span
        style={{
          margin: "auto",
          paddingLeft: "1em",
          paddingRight: "1em",
          textAlign: "center",
        }}
      >
        {text}
      </span>
    </div>
  );
}

//  ______________________________________________________________________________________________

export default function TruckLite() {
  const outcomeRef = useRef(null);
  const processRef = useRef(null);
  const backRef = useRef(null);

  return (
    <>
    <Container>
      <Grid stackable>
        <Grid.Row centered>
          <Grid.Column width={14} textAlign="center">
            <div ref={backRef}>
              <Header as="h2">Trailer Ready for Truck Drivers</Header>
            </div>
            <img src={truck_header} alt="tex" style={{ marginTop: "2em" }} />
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}
        {/* Intro and Role */}

        <Grid.Row style={{ margin: "2% 0px 8%" }}>
          <Grid.Column width={11}>
            <Header as="h3" style={{ marginRight: "30%" }}>
              Improve a driver's efficiency by using sensor data to provide the
              most important information only when needed.
            </Header>
            <p style={{ marginTop: "1em" }}>
              Truck-Lite approached us to help them utilize information 
              collected by connected technologies on trucks and trailers and
              provide it to the drivers in a useful and non-distracting manner.{" "}
              <b>
                Trailer Ready is a mobile application that provides important
                information about the trailer when it is needed, helps to assess
                the truck and trailer's health faster and allows drivers to
                override any decision made by the system.
              </b>
            </p>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={4}>
            <Header as="h4">My Role</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>
              UX/ UI Designer, Project Leader for Ideation, Business Roadmapping
            </p>

            <Header as="h4">Team</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>
              Me + 1 UX/ UI Designer, 2 Engineers, 2 Business Strategists
            </p>

            <Header as="h4">Duration</Header>
            <p style={{ margin: "-1.5% 0px 3%" }}>4 months (Jan - May 2019)</p>
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}
        {/* About the User */}
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">Truck Drivers have complex jobs</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ marginBottom: "8%" }}>
          <Grid.Column centered textAlign="center" width={5}>
            <Icon
              name="shipping fast"
              size="big"
              style={{ paddingBottom: "1.5em" }}
            />
            <p>
              <strong>Accountability</strong> for the trailer's condition and
              getting it from point A to B on schedule sits with the driver.
            </p>
          </Grid.Column>

          <Grid.Column centered textAlign="center" width={5}>
            <Icon name="road" size="big" style={{ paddingBottom: "1.5em" }} />
            <p>
              Drivers have a lot of <strong>distractions</strong>. 71% of large
              truck crashes occur due to distractions like CB radio, calls,
              taking notes etc.
            </p>
          </Grid.Column>

          <Grid.Column centered textAlign="center" width={5}>
            <Icon name="cloud" size="big" style={{ paddingBottom: "1.5em" }} />
            <p>
              <strong>Fleet Data</strong> is proliferating. There are multiple
              sensors on the truck and most drivers do not have access to the
              sensor data.
            </p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Day in the Life*/}

        <Grid.Row>
          <Grid.Column>
            <ContentHeader
              titleh3="A day in the life of a Truck Driver"
              subtitle1="15 Driver Interviews . 4 Ride-Alongs"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={12}>
            <Image src={truck_journey} />
            <p
              style={{
                marginTop: "2em",
                marginLeft: "0.5em",
                textAlign: "left",
                paddingRight: "30%",
              }}
            >
              <strong>CSA = Compliance, Safety, Accountability​</strong>
              <br />
              <span style={{ fontStyle: "italic" }}>
                It is the safety compliance and enforcement program of the
                Federal Motor Carrier Safety Administration (FMCSA) that holds
                motor carriers and drivers accountable for their role in safety.
              </span>
            </p>
          </Grid.Column>

          <Grid.Column width={4}>
            <Header as="h4">01</Header>
            <p style={{ marginTop: "-0.5em" }}>
              Drivers perform CSA of 3-5 trailers per day. It is
              <b> time consuming, taking up to 15-20 min each.​</b>
            </p>
            <Header as="h4">02</Header>
            <p style={{ marginTop: "-0.5em" }}>
              They do not know ahead of time if the next
              <b> trailer they are picking up has faults​.</b> If there are
              faults, they have to wait for them to be fixed.
            </p>
            <Header as="h4">03</Header>
            <p style={{ marginTop: "-0.5em" }}>
              Drivers <b>do not have access to sensor data</b> most times. When
              they do, they don't rely on it completely.
            </p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Opportunities */}

        <Grid.Row>
          <Grid.Column width={15}>
            <Header as="h3">Opportunities</Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "0.5em" }}>
                <strong>
                  Improve driver safety by showing the most important
                  information only when it is needed
                </strong>{" "}
                and recommend the next best action.
              </li>
              <li style={{ marginBottom: "0.5em" }}>
                <strong>
                  Provide timely, instructive and actionable information{" "}
                </strong>
                to the driver to facilitate assessment of truck health.
              </li>
              <li>
                <strong>Empower drivers to make better decisions </strong>by
                communicating the impact of their driving choices in real time.
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginTop: "-1em", marginBottom: "8%" }}>
          <Grid.Column>
            {/* current property refers to the node */}
            <Button
              onClick={() => {
                outcomeRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "start",
                });
              }}
              style={{ marginRight: "1%" }}
            >
              Outcome <Icon name="caret right" />
            </Button>

            <Button
              onClick={() => {
                processRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "start",
                });
              }}
            >
              Process
              <Icon name="caret right" />
            </Button>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={4}>
            <Header as="h3">Existing CSA Experience</Header>
            <p>
              The current CSA sheet has
              <strong> no grouping of similar parts </strong>and the parts are
              just listed in alphabetical order.
              <br />
              <br />
              The back of the sheet is for
              <strong> marking physical damages. </strong>It has drawings of
              different truck and trailer types which might not accurately
              represent the actual one the driver is driving.
            </p>
          </Grid.Column>
          <Grid.Column width={6}>
            <Image src={truck_csa_1} />
          </Grid.Column>
          <Grid.Column width={6}>
            <Image src={truck_csa_2} />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column>
            <Header as="h3">Conceived Business Impact</Header>
            <p style={{ marginTop: "-0.5em" }}>
              Working time of truck drivers: 10 hrs/ day → 6 days/ week → 50
              weeks/ year.
              <br />
              Per our calculation, our solution can
              <b>
                {" "}
                reduce 5 min/ CSA check or ~ 20 min/ day and thus save 100
                hours/ year/ driver.{" "}
              </b>
              <br />
              This would result in increased number of deliveries, and thus
              increase profits of trucking companies.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid doubling columns={4}>
        <Grid.Row centered>
          <Grid.Column width={3}>
            <CircleWith text="Increase productivity by pre-populating CSA​" />
          </Grid.Column>

          <Grid.Column width={3}>
            <CircleWith text="Increase number of deliveries​" />
          </Grid.Column>

          <Grid.Column width={3}>
            <CircleWith text="Ease of use of the solution​" />
          </Grid.Column>

          <Grid.Column width={3}>
            <CircleWith text="Improvement in Compliance" />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <HorizontalRule size="Large" />
      {/* ________________________________________________________________________________ */}

      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <div ref={outcomeRef}>
              {/* make reference: outcomeRef is referring to this node */}
              <ContentHeader
                titleh2="Trailer Ready App"
                subtitle1="Uses sensor data to optimize workflows"
                subtitle2="Seamlessly integrates into drivers' routines"
              />
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered verticalAlign="middle" style={{ marginTop: "1em" }}>
          <Grid.Column width={5} style={{ paddingRight: "3em" }}>
            <Image src={truck_login} alt="login screen" />
          </Grid.Column>

          <Grid.Column width={1} />
          <Grid.Column width={6}>
            <Header as="h4" style={{ color: "#545454" }}>
              FEATURES
            </Header>
            <>
              <img
                src={truck_CSACheck}
                style={{
                  verticalAlign: "middle",
                  height: "3em",
                  marginBottom: "1em",
                }}
                alt="CSA check helper"
              />
              <span style={{ marginLeft: "0.8em" }}>
                <b>CSA Check Helper</b>
              </span>
            </>
            <br />
            <>
              <img
                src={truck_healthHistory}
                style={{
                  verticalAlign: "middle",
                  height: "3em",
                  marginBottom: "1em",
                }}
                alt="truck health history"
              />
              <span style={{ marginLeft: "0.8em" }}>
                <b>Trailer Health History</b>
              </span>
            </>
            <br />
            <>
              <img
                src={truck_prePickup}
                style={{
                  verticalAlign: "middle",
                  height: "3em",
                  marginBottom: "1em",
                }}
                alt="pre-pickup rundown"
              />
              <span style={{ marginLeft: "0.8em" }}>
                <b>Pre-Pickup Rundown</b>
              </span>
            </>
            <br />
            <>
              <img
                src={truck_notificationLevels}
                style={{
                  verticalAlign: "middle",
                  height: "3em",
                  marginBottom: "1em",
                }}
                alt="different levels of notifications"
              />
              <span style={{ marginLeft: "0.8em" }}>
                <b>Different level of notifications</b>
              </span>
            </>
          </Grid.Column>
          <Grid.Column width={2} />
        </Grid.Row>

        <HorizontalRule size="Small" />
        {/* ________________________________________________________________________________ */}

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={12}>
            <ContentHeader
              titleh3="CSA Check Helper"
              subtitle1="Incorporates information generated by sensors"
              subtitle2="Records CSA for future reference"
            />

            <p>
              By digitizing the CSA, clustering similar activities and
              pre-populating the checkboxes using sensor data, the Trailer Ready
              app{" "}
              <strong>
                {" "}
                reduces the time truck drivers spend doing the CSA.{" "}
              </strong>
              Drivers can override any sensor reading in case they spot a
              discrepancy.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={4} style={{ marginTop: "1.5em" }}>
            <Header as="h4" style={{ color: "#545454" }}>
              Truck and Trailer CSA
            </Header>
            <p>
              <b>
                A list of truck and trailer components to be checked off with
                the option to jump to a particular component
              </b>{" "}
              in the cluster. On clicking a category name, the whole category
              can be checked off.
              <br />
              <br />
              If the <b>pre-fill toggle</b> is switched on in the settings, then
              based on sensor information, particular checkboxes are
              automatically checked/ marked as faulty, with the driver having an
              option to override the decision.
            </p>
            <ReadMoreLink
              moreTitle="Checking off multiple items"
              lessTitle="Checking off multiple items"
            >
              This insight comes from user testing in which we saw drivers
              checking off multiple items in the CSA list in one go.
            </ReadMoreLink>

            <ReadMoreLink
              moreTitle="Physical damage reporting"
              lessTitle="Physical damage reporting"
            >
              Only for reporting physical damages. Drivers can click a picture
              of the physical damage, mark it, and assign a damage type code.
            </ReadMoreLink>

            <ReadMoreLink moreTitle="Why Dark Mode?" lessTitle="Why Dark Mode?">
              <b>Better contrast ratio</b>. Drivers usually start their trips
              early in the morning when it is still dark, so dark mode can help
              them adjust to the screen better.
            </ReadMoreLink>
          </Grid.Column>
          <Grid.Column width={4}>
            <img
              src="images/TruckLite/truck_CSA.gif"
              alt="gif of truck csa list experience"
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <img src={truck_CSADeconstructed} alt="CSA deconstructed" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "1.5em" }}>
          <Grid.Column width={9}>
            <Header as="h4" style={{ color: "#545454" }}>
              CSA Check User Flow
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              Issues with Vehicle Parts and Physical Damages are reported with a
              consent action which serves as a signature. A summary of issues
              being reported is shown before submitting the CSA Report.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <img src={truck_CSAFlow} alt="CSA process" />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />

        {/* ________________________________________________________________________________ */}
        {/* Trailer Health History */}

        <Grid.Row style={{ marginBottom: "3em" }}>
          <Grid.Column width={12}>
            <ContentHeader
              titleh3="Trailer Health History and Pre-Pickup Rundown"
              subtitle1="Helps driver preempt situations that could go wrong"
              subtitle2="Enables assessment of truck and trailer health before pickup"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4} style={{ marginTop: "1.5em" }}>
            <p>
              <b>Trailer Health History</b> provides a list of issues reported
              by the driver during CSA, previous issues that have arisen and
              sensor status summary. It is under the <b>Current</b> tab.
              <br />
              <br />
              <b>Pre-Pickup Rundown</b> provides the pickup location of the next
              vehicle, the truck and trailer number plates and the previously
              reported issues and repairs. It is found in the <b>Next</b> tab.
            </p>
            <ReadMoreLink
              moreTitle="Pre-emptive is Key"
              lessTitle="Pre-emptive is Key"
            >
              Drivers change trailers 1-3 times a day. Ever so often, they find
              an issue in the trailer upon receipt.{" "}
              <b>The Pre-Pickup Rundown</b> can eliminate the time drivers spend
              waiting while the issues get repaired.
            </ReadMoreLink>
          </Grid.Column>
          <Grid.Column width={4}>
            <img src={truck_health2} alt="truck Health Current" />
          </Grid.Column>

          <Grid.Column width={4}>
            <img src={truck_health1} alt="truck Health Next" />
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid.Row>

        <HorizontalRule size="Small" />
        {/* ________________________________________________________________________________ */}
        {/* Notifications */}

        <Grid.Row style={{ marginBottom: "2em" }}>
          <Grid.Column width={12}>
            <ContentHeader
              titleh3="Different Levels of Notifications"
              subtitle1="Takes into account the criticality of the situation"
              subtitle2="Delivers notifications in 3 levels of priority"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4} style={{ marginTop: "1.5em" }}>
            <p>
              <b>Notifications</b> are presented to the driver in the least
              intrusive manner. Although notifications are distinguished based
              on priority of whether they are delivered to driver in the form of
              an in-app or push notification or a call, all of them can be found
              in the notifications section of the application.
            </p>

            <ReadMoreLink
              moreTitle="3 Levels of Priority"
              lessTitle="3 Levels of Priority"
            >
              The levels of priority reduce information overload to the driver
              while driving. Only the most critical notification requires
              immediate response.
            </ReadMoreLink>
          </Grid.Column>

          <Grid.Column width={4}>
            <img
              src={truck_notifications}
              alt="different levels of notifications"
            />
          </Grid.Column>

          <Grid.Column width={8} verticalAlign="middle">
            <Table celled style={{ margin: "1em" }} unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Urgency</Table.HeaderCell>
                  <Table.HeaderCell>Execution</Table.HeaderCell>
                  <Table.HeaderCell>Scenario</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row style={{ backgroundColor: "#FFD9D8" }}>
                  <Table.Cell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon name="circle" size="large" color="red" />
                      <span style={{ marginLeft: "0.5em" }}>
                        <b>Critical</b> <br />
                        Mandatory During Drive
                      </span>
                    </div>
                  </Table.Cell>
                  <Table.Cell singleLine>
                    <ul
                      style={{
                        paddingLeft: "1.5em",
                        marginTop: "0",
                        marginBottom: "0",
                      }}
                    >
                      <li>Automated Call</li>
                      <li>Push Notification</li>
                      <li>In-App Prompt</li>
                    </ul>
                  </Table.Cell>

                  <Table.Cell>
                    Piece of equipment punctured/ faulty: e.g. Tire
                  </Table.Cell>
                </Table.Row>

                <Table.Row style={{ backgroundColor: "#FFEEC7" }}>
                  <Table.Cell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        name="circle"
                        size="large"
                        style={{ color: "#F09303" }}
                      />
                      <span style={{ marginLeft: "0.5em" }}>
                        <b>Semi-Critical</b> <br />
                        Suggested During Stop
                      </span>
                    </div>
                  </Table.Cell>
                  <Table.Cell singleLine>
                    <ul style={{ paddingLeft: "1.5em" }}>
                      <li>Push Notification</li>
                      <li>In-App Prompt</li>
                    </ul>
                  </Table.Cell>

                  <Table.Cell>
                    <ul
                      style={{
                        paddingLeft: "1.5em",
                        marginTop: "0",
                        marginBottom: "0",
                      }}
                    >
                      <li>Sensor Failure</li>
                      <li>Pre-Trip Rundown</li>
                      <li>Next Trailer Assigned</li>
                      <li>Prediction that a sensor is about to fail</li>
                    </ul>
                  </Table.Cell>
                </Table.Row>

                <Table.Row style={{ backgroundColor: "#D6E7FF" }}>
                  <Table.Cell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        name="circle"
                        size="large"
                        style={{ color: "#385EE5" }}
                      />
                      <span style={{ marginLeft: "0.5em" }}>
                        <b>Non-Urgent</b> <br />
                        Not Mandatory
                      </span>
                    </div>
                  </Table.Cell>
                  <Table.Cell singleLine>
                    <ul style={{ paddingLeft: "1.5em" }}>
                      <li>In-App Prompt</li>
                    </ul>
                  </Table.Cell>

                  <Table.Cell>Pre-trip check rundown sent to driver</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />

        {/* ________________________________________________________________________________ */}
        {/* Features Supporting the MVP */}

        <Grid.Row>
          <Grid.Column width={12}>
            <ContentHeader
              titleh3="License Plate Linking"
              subtitle1="Feature Supporting the MVP"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "6%" }}>
          <Grid.Column width={4} style={{ marginTop: "4%" }}>
            <p>
              By{" "}
              <b>
                mapping sensors mounted on each truck and trailer to its license
                plate number
              </b>
              , storing and fetching sensor data on linking a license plate
              becomes easy. This also brings a huge business value to the client
              for partnering with other sensor manufacturers.
              <br />
              <br />
              <b>
                A truck or trailer's license plate can be captured by typing, or
                by clicking a picture of the license plate.
              </b>{" "}
              After clicking a picture, the user can verify the license plate
              number before linking.
            </p>
          </Grid.Column>
          <Grid.Column width={12}>
            <img
              src={truck_licenseLinking}
              alt="license linking experience"
              style={{ padding: "1em" }}
            />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row centered>
          <Grid.Column verticalAlign="middle">
            <Button
              onClick={() => {
                backRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "start",
                });
              }}
            >
              Back to Top
              <Icon
                name="caret up"
                alt="caret up"
                style={{ marginLeft: "0.5em", marginRight: "-0.5em" }}
              />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <HorizontalRule size="Large" />
      {/* ________________________________________________________________________________ */}
      {/* PRODUCT DEVELOPMENT PROCESS */}
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <div ref={processRef}>
              <Header as="h2">Product Development Process</Header>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ margin: "2% 0px 8%" }}>
          <Grid.Column width={14}>
            <img src={truck_process} alt="product development process" />
          </Grid.Column>
        </Grid.Row>

        {/* Problem Statement */}
        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h3">Problem Statement</Header>
            <Header as="h4" style={{ color: "#545454", marginTop: "-0.5em" }}>
              Background Research
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              We started the project by identifying and researching deeper about
              the problem area. we used <b>Affinity Mapping</b> to formulate
              groupings and patterns in the information we found. One major
              category is <b>data presentation</b>. Different forms of data
              feeds into the myriad of distractions that truckers experience
              daily. Yet at the same time, we realized that it is not a
              standalone issue, as it also correlates to loading and unloading
              efficiency, truck and trailer maintenance, and driver mental
              wellbeing. This helped us grasp the complexity of the issue at
              hand.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={3}>
            <HeroImage
              src={truck_Research1}
              imageHeight="20em"
              alt="secondary research"
              caption="Secondary Research"
            />
          </Grid.Column>

          <Grid.Column width={7}>
            <HeroImage
              src={truck_Research3}
              imageHeight="20em"
              caption="Affinity Mapping"
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <HeroImage
              src={truck_Research2}
              imageHeight="20em"
              caption="Driver Journey"
            />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column>
            <Header as="h4" style={{ color: "#545454" }}>
              Understanding the End User
            </Header>
            <Header as="h5" style={{ marginTop: "-0.5em" }}>
              A Truck Driver's Journey Map
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={12}>
            <Image src={truck_journey} />
            <p
              style={{
                marginTop: "2em",
                marginLeft: "0.5em",
                textAlign: "left",
                paddingRight: "30%",
              }}
            >
              <strong>CSA = Compliance, Safety, Accountability​</strong>
              <br />
              <span style={{ fontStyle: "italic" }}>
                It is the safety compliance and enforcement program of the
                Federal Motor Carrier Safety Administration (FMCSA) that holds
                motor carriers and drivers accountable for their role in safety.
              </span>
            </p>
          </Grid.Column>

          <Grid.Column width={4}>
            <Header as="h4">01</Header>
            <p style={{ marginTop: "-0.5em" }}>
              Drivers perform CSA of 3-5 trailers per day. It is
              <b> time consuming, taking up to 15-20 min each.​</b>
            </p>
            <Header as="h4">02</Header>
            <p style={{ marginTop: "-0.5em" }}>
              They do not know ahead of time if the next
              <b> trailer they are picking up has faults​.</b> If there are
              faults, they have to wait for them to be fixed.
            </p>
            <Header as="h4">03</Header>
            <p style={{ marginTop: "-0.5em" }}>
              Drivers <b>do not have access to sensor data</b> most times. When
              they do, they don't rely on it completely.
            </p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h4" style={{ color: "#545454" }}>
              PESTLE Analysis
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              We used PESTLE (
              <b>
                Political, Economic, Social, Technological, Legal and
                Environmental
              </b>
              ) analysis to assess the viability of the solution space and
              identify factors that may impact our product development. This
              exercise resulted in over 100+ PESTLE factors that have been
              summarized. Expand to view the explanations.
            </p>

            <ReadMoreLink
              moreTitle="Driving is Dangerous"
              lessTitle="Driving is Dangerous"
            >
              <cite>
                <a href="https://www.trucks.com/2016/12/19/truck-driver-deadliest-job/" target="_blank"rel="noopener noreferrer">
                  Truck driving remains one of the deadliest occupations in the
                  country with
                </a>
              </cite>{" "}
              745 killed on the job in 2015. Top reasons for truck failures
              include tire failure, wheel bearing problems, and trailer catching
              fire.{" "}
              <cite>
                <a href="https://www.researchgate.net/publication/318810492_Context-adaptive_support_information_for_truck_drivers_An_interview_study_on_its_contents_priority" target="_blank"rel="noopener noreferrer">
                  Human error is widely recognized as a major contributing
                  factor in accidents.{" "}
                </a>
              </cite>
              Therefore, empower drivers to make better choices by communicating
              the impact of their driving choices in real time.
            </ReadMoreLink>

            <ReadMoreLink
              moreTitle="Drivers have Several Distractions"
              lessTitle="Drivers have Several Distractions"
            >
              <cite>
                <a href="https://www.fmcsa.dot.gov/safety/driver-safety/cmv-driving-tips-driver-distraction" target="_blank"rel="noopener noreferrer">
                  According to FMCSA, 71% of large-truck crashes occurred when
                  the driver was doing something else besides driving.{" "}
                </a>
              </cite>
              Compared to passenger car users, truck drivers spend more time
              behind the wheel handling complex driving tasks and view ten times
              more information. We see a need to consolidate communication
              platforms to reduce interfaces in the truck to reduce visual
              distractions while driving.
            </ReadMoreLink>

            <ReadMoreLink
              moreTitle="Data is King for fleet operators and the government"
              lessTitle="Data is King for fleet operators and the government"
            >
              A large amount of data is collected through connected technology
              on trucks starting with the ELD. In 2015, the FMCSA published the
              final ELD Mandate requiring drivers to{" "}
              <cite>
                <a href="https://www.trucker.com/technology/article/21746561/make-the-rise-of-trucking-telematics-work-for-you" target="_blank"rel="noopener noreferrer">
                  electronically log their drive time,
                </a>
              </cite>{" "}
              saving time by reducing paperwork and hours of service logging.
              Telematics like Road Ready, has{" "}
              <cite>
                <a href="https://blog.equinix.com/blog/2018/02/20/how-telematics-and-the-iot-are-transforming-commercial-transportation/" target="_blank"rel="noopener noreferrer">
                  benefitted stakeholders such as fleet operators and insurance
                  companies to assess and govern truck drivers’ performance.
                </a>
              </cite>{" "}
              Recent advances have made data more accessible, and it promises to
              provide timely, instructional and actionable information to the
              driver to facilitate assessment of truck health.10 Regulations
              require drivers to electronically log their drive time/ hours of
              service
            </ReadMoreLink>

            <ReadMoreLink
              moreTitle="Drivers are Concerned about Data Collection"
              lessTitle="Drivers are Concerned about Data Collection"
            >
              Truckers are much more concerned with how the ELD mandate impacts
              their wages since it effectively makes it impossible for drivers
              to work more hours than the legally mandated limit. Drivers are
              naturally concerned about how the data on their driving behavior
              is being used, who can then access that data, and for what
              purpose.12 Such data is protected under the Data Protection Act
              (1998), yet{" "}
              <cite>
                <a href="https://www.bls.gov/ooh/transportation-and-material-moving/heavy-and-tractor-trailer-truck-drivers.htm" target="_blank"rel="noopener noreferrer">
                  drivers may not be aware of their rights as fewer than half of
                  them have a high school diploma or post-secondary degree.
                </a>
              </cite>
            </ReadMoreLink>

            <ReadMoreLink
              moreTitle="Big Rigs are Complicated and Expensive"
              lessTitle="Big Rigs are Complicated and Expensive"
            >
              The investment a fleet operator or owner-operator makes in a new
              cab and trailer is significant. On average, a brand-new day cab
              costs about $113,000 and a sleeper about $125,000. On top of this,
              <cite>
                {" "}
                <a href="https://askthetrucker.com/what-it-really-costs-to-own-a-commercial-truck/" target="_blank"rel="noopener noreferrer">
                  new standard trailers add an extra $50,000 as per mile
                  operating cost.
                </a>
              </cite>{" "}
              Annual operating costs are estimated to costs about{" "}
              <cite>
                <a href="https://www.thetruckersreport.com/infographics/cost-of-trucking/" target="_blank"rel="noopener noreferrer">
                  $180,000 or $1.38 per mile on the road.
                </a>
              </cite>
            </ReadMoreLink>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />

        {/* ________________________________________________________________________________ */}
        {/* Identified Opportunities */}
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">Identified Opportunities</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row verticalAlign="middle" style={{ marginBottom: "8em" }}>
          <Grid.Column width={5}>
            <Header as="h4" style={{ color: "#545454" }}>
              Product Opportunity Gap
            </Header>
          </Grid.Column>
          <Grid.Column centered width={11}>
            <div
              style={{
                border: "1px solid #cecece",
                borderRadius: "0.3em",
                padding: "0.8em",
                textAlign: "center",
              }}
            >
              <p>
                <b>
                  Road Ready to provide the most important information about the
                  trailer to the driver only when it is needed to assess the
                  truck’s health and recommending the next best action for them
                  to take.
                </b>
              </p>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8em" }}>
          <Grid.Column width={5}>
            <Header as="h4" style={{ color: "#545454" }}>
              Service Blueprint
            </Header>
            <p>
              The Service Blueprint helped in identifying areas of intervention.
              With this, the team developed a shared understanding of the driver
              journey and ecosystem.
              <br />
              <br />
              <br />
              <br />
              <b>Key</b>
            </p>
            <div
              style={{
                border: "1px solid #cecece",
                borderRadius: "0.3em",
                padding: "0.8em",
                marginRight: "1em",
              }}
            >
              <>
                <Icon name="circle" style={{ marginBottom: "1em" }} />
                <span style={{ marginLeft: "0.5em" }}>
                  Areas of Intervention
                </span>
              </>
              <br />
              <>
                <Icon
                  name="square full"
                  style={{ color: "#FBE86C", marginBottom: "1em" }}
                />
                <span style={{ marginLeft: "0.5em" }}>Personal Activities</span>
              </>
              <br />
              <>
                <Icon name="square full" style={{ color: "#F09303" }} />
                <span style={{ marginLeft: "0.5em" }}>Processes</span>
              </>
            </div>
          </Grid.Column>
          <Grid.Column width={11}>
            <img src={truck_ServiceBlueprint} alt="service blueprint" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "2%" }}>
          <Grid.Column width={12}>
            <Header as="h4" style={{ color: "#545454" }}>
              Value Flow
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              <b>How Trailer Ready offers value to its stakeholders</b> <br />
              <br />
              At the intersection of technology, safety, and human behavior we
              believe there is opportunity to create value for all of the
              stakeholders.{" "}
              <b>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://csa.fmcsa.dot.gov/Documents/CSA-Drivers-PPTX.pptx"
                  style={{ color: "#252525" }}
                >
                  CSA requires the driver complete the pre-trip checklist
                </a>
              </b>{" "}
              to help them prevent accidents and be prepared in the event of a
              roadside inspection or crash.{" "}
              <b>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://blog.equinix.com/blog/2018/02/20/how-telematics-and-the-iot-are-transforming-commercial-transportation/"
                  style={{ color: "#252525" }}
                >
                  Fleet managers view telematics and the Internet of Things as
                  critical
                </a>
              </b>{" "}
              for monitoring and improving driver performance. Additionally,{" "}
              <b>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.fmcsa.dot.gov/safety/driver-safety/cmv-driving-tips-driver-distraction"
                  style={{ color: "#252525" }}
                >
                  drivers need help managing the distracting communication
                </a>
              </b>{" "}
              and information they receive.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <img src={truck_ValueFlow} alt="value flow" />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />

        {/* ________________________________________________________________________________ */}
        {/* Product Requirements */}
        <Grid.Row>
          <Grid.Column width={4} style={{ marginTop: "1.5em" }}>
            <Header as="h3">Product Requirements</Header>
            <p>
              By prioritizing the identified opportunities, we formulated
              product requirements for Trailer Ready.
            </p>
          </Grid.Column>
          <Grid.Column width={12}>
            <Table celled style={{ margin: "1em" }} columns={3} unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Must</Table.HeaderCell>
                  <Table.HeaderCell>Should</Table.HeaderCell>
                  <Table.HeaderCell>Could</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    The product is fast, accurate, and doesn't distract the
                    driver.
                  </Table.Cell>
                  <Table.Cell>
                    The product is modular and adaptable so it can evolve with
                    emerging technology.
                  </Table.Cell>
                  <Table.Cell>
                    The product has affordances for the normal noise in the cab
                    and is not overly persistent or annoying.
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    The product provides actionable information to the driver at
                    the right time and empowers them to make the right decision.
                  </Table.Cell>
                  <Table.Cell>
                    The product provides a feeling of familiarity.
                  </Table.Cell>
                  <Table.Cell>
                    The product conveys the quality of wisdom and feels at the
                    same level as the driver.
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    The product requires minimal knowledge to use and is simple
                    and easy to view.
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell />
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    The product seamlessly integrates into the driver's existing
                    routine.
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell />
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />

        {/* ________________________________________________________________________________ */}
        {/* Identified Concept */}
        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h3">Identified Concept</Header>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={5}>
            <Header as="h4" style={{ color: "#545454", marginTop: "1.5em" }}>
              Ideation & Concept Clustering
            </Header>
            <p>
              The product requirements and insights from talking with truck
              drivers and fleet managers led us to come up with 100+ ideas which
              were sorted into 26 individual categories. We used a variety of
              ideation methods such as:{" "}
              <b>
                constrained optimization, rose-thorn-bud, collaborative
                sketching, reverse assumptions
              </b>{" "}
              etc. After concept clustering, the ideas were shortlisted to 4
              preliminary concepts.
            </p>
          </Grid.Column>

          <Grid.Column width={5}>
            <HeroImage
              src={truck_concept_2}
              imageHeight="20em"
              caption="Ideation Method: Rose - Thorn - Bud"
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <HeroImage
              src={truck_concept_1}
              imageHeight="20em"
              caption="Concept Clustering"
            />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column>
            <Header as="h4" style={{ color: "#545454" }}>
              Preliminary Concepts
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "8%" }}>
          <Grid.Column width={4}>
            <img
              src={truck_relySensor}
              alt="rely-a-sensor"
              style={{ display: "block", padding: "0 3.5em" }}
            />
            <Header as="h5">Rely-a-Sensor</Header>
            <p style={{ marginTop: "-0.5em" }}>
              As the driver visually inspects the trailer, sensors simply change
              color to indicate their health. Drivers can conduct their CSA
              based on this information, eliminating the need for them to check
              parts by climbing under and around the trailer.
            </p>
          </Grid.Column>

          <Grid.Column width={4}>
            <img
              src={truck_sensorRead}
              alt="sensor-read"
              style={{ display: "block", padding: "0 3.5em" }}
            />
            <Header as="h5">SensorRead Assistant</Header>
            <p style={{ marginTop: "-0.5em" }}>
              It is mounted in the truck cab and integrates with the GPS,
              providing alerts and notifications to the driver only when needed,
              thus enabling drivers to obtain the data they want. The driver can
              take SensorRead Assistant around during CSA inspection.
            </p>
          </Grid.Column>

          <Grid.Column width={4}>
            <img
              src={truck_holoLight}
              alt="holo-light"
              style={{ display: "block", padding: "0 3.5em" }}
            />
            <Header as="h5">HoloLight</Header>
            <p style={{ marginTop: "-0.5em" }}>
              HoloLight is docked on the trailer and can be aimed at a part
              being inspected during the CSA check. The light turns red if it
              senses an issue and is visible in low light and bright light
              conditions.
            </p>
          </Grid.Column>

          <Grid.Column width={4}>
            <img
              src={truck_CSATablet}
              alt="CSA Tablet"
              style={{ display: "block", padding: "0 3.5em" }}
            />
            <Header as="h5">Digital CSA Tablet</Header>
            <p style={{ marginTop: "-0.5em" }}>
              Pairs to the trailer and keeps a record of a trailer’s past
              maintenance and health record. The sensors in return, communicate
              the current truck and trailer's health status. In case of a
              discrepancy, the CSA Tablet prompts the driver to contact the
              fleet manager.
            </p>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column width={9}>
            <Header as="h4" style={{ color: "#545454" }}>
              Build-A-Thon and PUGH Matrix
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              We aligned on what we want to test and gather more working
              knowledge about. This led us to prototype the in-cab experience
              and CSA check simulation that a driver performs routinely.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ margin: "2% 0px 8%" }}>
          <Grid.Column width={5}>
            <HeroImage
              src={truck_BuildAThon_1}
              imageHeight="20em"
              alt="In-cab Experience"
              caption="In-cab Experience"
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <HeroImage
              src={truck_BuildAThon_2}
              imageHeight="20em"
              caption="CSA Check Simulation"
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <HeroImage
              src={truck_PUGH}
              imageHeight="20em"
              caption="PUGH Matrix and Assessment"
            />
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as="h4" style={{ color: "#545454" }}>
              User Feedback
            </Header>
            <Header as="h5" style={{ marginTop: "-0.5em" }}>
              2 Fleet Managers . 6 Truck Drivers
            </Header>

            <Header as="h5">
              <Icon name="smile outline" />
              Validation
            </Header>
            <ul
              style={{
                listStyleType: "none",
                marginBottom: "3em",
              }}
            >
              <li style={{ marginBottom: "0.5em" }}>
                <strong>
                  Drivers like the idea of the solution living in the trailer.
                </strong>
              </li>
              <li style={{ marginBottom: "0.5em" }}>
                <strong>Tech is not an Issue. </strong>Drivers are adept at
                using technology which includes smartphones, GPS system, ELD
                etc. <strong>The digital CSA idea was well received</strong>,
                and many were open to integration.
              </li>
              <li>
                <strong>Empower drivers to make better decisions </strong>by
                communicating the impact of their driving choices in real time.
              </li>
            </ul>

            <Header as="h5">
              <Icon name="meh outline" />
              Scope for Advancement
            </Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "0.5em" }}>
                <strong>The cab is overcrowded. </strong>Drivers are reluctant
                to have another device in their already crowded cabs.
              </li>
              <li style={{ marginBottom: "0.5em" }}>
                <b>
                  Drivers don’t really need to know specific sensor data all the
                  time
                </b>
                , they just want to be notified when something is out of the
                ordinary
              </li>
              <li>
                <b>There is a lot of noise inside the cab</b>, which can make
                voice commands and interactions difficult.
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />

        {/* ________________________________________________________________________________ */}
        {/* Product */}
        <Grid.Row style={{ marginBottom: "4em" }}>
          <Grid.Column width={6}>
            <Header as="h3">Product</Header>
            <p style={{ marginTop: "-0.5em" }}>
              Based on feedback from our clients and drivers, we{" "}
              <b>focused on creating a Digital CSA Experience</b> for Drivers.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "6%" }}>
          <Grid.Column width={6}>
            <Header as="h4" style={{ color: "#545454", marginTop: "1.5em" }}>
              UI/ UX Iterations
            </Header>
            <p style={{ marginTop: "-0.5em" }}>
              After a year of working in the industry as UX Designers, Sisi and
              I decided to review this project and made UI updates - and ended
              up making the app in dark mode.
            </p>
            <Header as="h4">01</Header>
            <p style={{ marginTop: "-0.5em" }}>
              <ul
                style={{
                  paddingLeft: "1.5em",
                }}
              >
                <li>
                  Designed to the best of our ability during grad school.
                  Although we pretty much nailed the UX, there was a lot of room
                  for improvement on the UI end.
                </li>
                <li>This version of the app was presented to the client.</li>
                <li>Followed Material design guidelines.</li>
              </ul>
            </p>
            <Header as="h4">02</Header>
            <p style={{ marginTop: "-0.5em" }}>
              <ul
                style={{
                  paddingLeft: "1.5em",
                }}
              >
                <li>
                  Button treatment of <b>‘Pre-fill’ changed to a toggle.</b>{" "}
                </li>
                <li>Extra bounding boxes and accordions removed. </li>
                <li>
                  <b>List and 3D views moved to page header</b> since they are
                  navigation elements.
                </li>
                <li>Followed iOS design guidelines. </li>
              </ul>
            </p>
            <Header as="h4">03</Header>
            <p style={{ marginTop: "-0.5em" }}>
              <ul
                style={{
                  paddingLeft: "1.5em",
                }}
              >
                <li>
                  Moved over to dark mode to reduce eye strain for drivers
                </li>
                <li>
                  <b>A summary page added after CSA completion</b> so that the
                  drivers may review the reported defects/ damages before
                  providing consent and submitting.
                </li>
                <li>
                  The workflow of marking physical damage modified from{" "}
                  <b>
                    annotating a 3D model to adding markup on a clicked picture
                  </b>{" "}
                  - this is also easier to achieve from a technical perspective
                  and the <b>phone orientation doesn't need be to changed.</b>
                </li>
              </ul>
            </p>
          </Grid.Column>

          <Grid.Column width={10}>
            <img src={truck_UxIteration_1} alt="UX iterations" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={6} />
          <Grid.Column width={10}>
            <img src={truck_UxIteration_2} alt="UX iterations" />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Small" />
        {/* ________________________________________________________________________________ */}
        {/* Reflection */}

        <Grid.Row style={{ marginBottom: "2em" }}>
          <Grid.Column width={12}>
            <Header as="h3">Reflection</Header>
            <Header as="h4" style={{ color: "#545454" }}>
              <Icon name="edit outline" />
              If I had more time I would..
            </Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "1em" }}>
                <b>Do more User Research. </b>In this project we had to run
                after trucks, catch drivers at their rest stop when they were
                taking a break to get their feedback. If this were a longer
                project, getting more driver feedback would have made the
                outcome even better, and predicted adaptability if this were to
                be launched.
              </li>
              <li>
                <b>
                  Design the app in light mode, do strict accessibility checks.{" "}
                </b>
                Most services that offer dark mode, also offer a light mode, and
                in some cases - an accessibility mode as well. It would have
                been worthwhile to explore these modes.
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h4" style={{ color: "#545454", marginTop: "-0.5em" }}>
              <Icon name="lightbulb outline" />
              What I learned from this project
            </Header>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ marginBottom: "1em" }}>
                <b>
                  Collaborating closely with engineers and business
                  professionals
                </b>{" "}
                was almost close to a real-world industry experience of working
                in a cross-functional team. I learnt that amidst technical
                feasibility and business goals,{" "}
                <b>it is important to advocate for user needs</b> as that can
                make or break the product.
              </li>
              <li>
                A well ironed out process leads to a successful product outcome
                which satisfies the stakeholders, and helps to align the team.
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row centered style={{ margin: "8% 0px" }}>
          <Grid.Column verticalAlign="middle">
            <Button
              onClick={() => {
                backRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "start",
                });
              }}
            >
              Back to Top
              <Icon
                name="caret up"
                alt="caret up"
                style={{ marginLeft: "0.5em", marginRight: "-0.5em" }}
              />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
    <SiteFooter />
    </>
  );
}
