import React from "react";

const Mailto = ({ email, subject, body, children }) => {
  const mailtoLink = `mailto:${email}?subject=${
    encodeURIComponent(subject) || ""
  }&body=${encodeURIComponent(body) || ""}`
  
  return (
    <a href={mailtoLink} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default Mailto;
