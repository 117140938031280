import React from "react";
import { Header} from 'semantic-ui-react';

export type Props = {
  titleh2?: string;
  titleh3?: string;
  titleh4?: string;
  titleh4grey?: string;
  subtitle1?: string;
  subtitle2?: string;
};

export default function ContentHeader({
  titleh2,
  titleh3,
  titleh4,
  titleh4grey,
  subtitle1,
  subtitle2,
}: Props) {
  return (
    <>
      <Header as="h2" style={{ marginBottom: "-0.7em" }}>{titleh2}</Header>
      <Header as="h3">{titleh3}</Header>
      <Header as="h4">{titleh4}</Header>
      <Header as="h4" style={{ color: "#545454", marginTop: "-0.5em", margin: "0px" }}>{titleh4grey}</Header>
      <Header as="h5" style={{ marginTop: "-0.5em", color: "#545454" }}>
        {subtitle1}<br />
        {subtitle2}
      </Header>
    </>
  );
}
