import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type Props = {
    sliderContent: React.ReactNode[];
    showDots?: boolean;
};

export default function ImageCarousel ({sliderContent, showDots = true}: Props) {
    
    const settings = {
      dots: showDots,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoPlaySpeed: 700,
      pauseOnHover: true,
      cssEase: "linear"
    };
  
    return (
      <Slider {...settings}>
            {sliderContent}
      </Slider>
    );
  };
  