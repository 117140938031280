import React from "react";
import { Container, Grid, Header, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
/* cSpell:disable */

import ContentHeader from "../components/ContentHeader";
import SiteFooter from "../components/SiteFooter";

const digitalindia = "images/DigitalIndia/digitalindia_hero_2.png";
const tulip_header = "images/Tulip/tulip_hero_2.png";
const trucklite_hero = "images/TruckLite/truck_hero_2.png";
const morphing_matter_hero = "images/MorphingMatter/morphingmatter_hero.png";
const star_graphic = "images/star.png";

export default function AllProjects() {
  return (
    <>
      <Container>
        <Grid stackable columns={2}>
          <Grid.Row centered style={{ margin: "6% 0px" }}>
            <Grid.Column width={8} style={{ position: "relative" }}>
              <Header
                as="h1"
                style={{
                  position: "absolute",
                  top: "12%",
                  left: "1%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                ❇️
              </Header>
              <Header as="h1" textAlign="center">
                Hello, I'm Harshika. I design experiences for digital and
                tangible interactions
              </Header>

              <Image
                src={star_graphic}
                style={{
                  position: "absolute",
                  bottom: "-12%",
                  left: "92%",
                  width: "32px",
                  height: "32px",
                  zIndex: -1,
                }}
              />
            </Grid.Column>
          </Grid.Row>
          {/* ________________________________________________________________________________ */}

          <Grid.Row style={{ marginBottom: "4%" }}>
            <Grid.Column width={8}>
              <Image fluid src={tulip_header} as={Link} to="/tulip" />
              <div style={{ margin: "6% 0px 0px 4%" }}>
                <ContentHeader
                  titleh4="Making digital manufacturing ops easy to use and scale"
                  subtitle1="Tulip Interfaces . 2020 - 21"
                />
              </div>
            </Grid.Column>

            <Grid.Column width={1} />

            <Grid.Column width={7} style={{ marginTop: "12%" }}>
              <Image fluid src={digitalindia} as={Link} to="/digitalindia" />
              <div style={{ margin: "6% 0px 0px 4%" }}>
                <ContentHeader
                  titleh4="Large scale training for upskilling front-line workers"
                  subtitle1="Microsoft . 2018"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          {/* ________________________________________________________________________________ */}

          <Grid.Row style={{ marginBottom: "3%" }}>
            <Grid.Column width={1} />

            <Grid.Column width={7}>
              <Image fluid src={trucklite_hero} as={Link} to="/trucklite" />
              <div style={{ margin: "6% 4% 0px 15%" }}>
                <ContentHeader
                  titleh4="Enabling truck drivers to complete their truck assessment 5 minutes faster and increase deliveries"
                  subtitle1="TruckLite . 2019"
                />
              </div>
            </Grid.Column>

            <Grid.Column width={1} />

            <Grid.Column
              width={7}
              style={{ marginTop: "12%", marginBottom: "6%" }}
            >
              <Image
                fluid
                src={morphing_matter_hero}
                as={Link}
                to="/morphingmatter"
              />
              <div style={{ margin: "6% 4% 0px 15%" }}>
                <ContentHeader
                  titleh4="Morphing artifacts for creative STEM explorations"
                  subtitle1="Morphing Matter Lab . 2018 - 22"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <SiteFooter />
    </>
  );
}