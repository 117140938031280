import { Header } from "semantic-ui-react";

// define a reusable component as an arrow function
export type Props = {
  src: string;
  displayImageAlt?: string;
  imageHeight?: any;
  projectTitle?: string;
  subTitle?: string;
  tags?: string;
  caption?: string;
  cornerRadius?: any;
  onClick?: () => void;
};

export default function HeroImage({
  src,
  displayImageAlt,
  imageHeight,
  projectTitle,
  subTitle,
  tags,
  caption,
  cornerRadius, onClick,
}: Props) {
  return (
    <div
      style={{
        marginBottom: "3em",
        textAlign: "center",
      }}
      onClick={onClick}
    >
      <img
        src={src}
        alt={displayImageAlt}
        style={{
          maxWidth: "100%",
          objectFit: "cover",
          maxHeight: "20em",
          width: "auto",
          height: imageHeight,
          borderRadius: cornerRadius,

          // aspectRatio: "1",
          // backgroundSize: "contain",
          // height: "auto",
          // maxHeight: "25em",
          // flex: 1,
          // flexDirection: "row",
        }}
      ></img>

      <p style={{ marginTop: "5px", color: "#333333" }}>{tags}</p>
      <p style={{ fontStyle: "italic", textAlign: "center" }}>{caption}</p>

      <Header as="h3" style={{ marginTop: "-5px", color: "#333333" }}>{projectTitle}</Header>
      
      <p style={{ marginTop: "-10px", color: "black", fontSize: "15px" }}>{subTitle}</p>
    </div>
  );
}
