import React from "react";
import { Icon, Grid, Image } from "semantic-ui-react";
import Mailto from "../components/Mailto";

const footerImage = "./images/footer.png";

export default function SiteFooter() {
  return (
    <Grid stackable style={{ marginTop: "8%"}}>
      <Grid.Row centered style={{ marginBottom: "-4.5em", zIndex: "5" }}>
        <Grid.Column textAlign="center">
          <span>Harshika Jain © 2021 - 2024</span>
          &nbsp;&nbsp;| &nbsp;&nbsp;

          <a
            href="https://www.linkedin.com/in/harshikajain/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="linkedin" size="large" link color="black" />
          </a>

          <Mailto
            email="harshikj@alumni.cmu.edu"
            subject="Connect - from website"
            body="Hey Harshika,"
          >
            <Icon name="mail outline" size="large" link color="black" />
          </Mailto>

          <a
            href="https://twitter.com/harshikerfuffle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="twitter" size="large" link color="black" />
          </a>

          <p style={{ fontSize: "0.8em", marginTop: "0.5em" }}>
            {"<"} Built with 💚 using React and Semantic UI {"/>"}
          </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Image
          src={footerImage}
          alt="footer"
          style={{width: "100%", height: "auto"}}/>
      </Grid.Row>
    </Grid>
  );
}
