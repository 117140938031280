import { Grid, Image, Container, Divider, Header } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

/* cSpell:disable */

import HorizontalRule from "../components/HorizontalRule";
import SiteFooter from "../components/SiteFooter";
import SiteHeader from "../components/SiteHeader";
import ResumeContent from "../components/ResumeContent";

//  ______________________________________________________________________________________

// const ADPList = "./images/adplist-swag.png";
const headShot = "./images/aboutme_1.png";
const Cornell_1_2023="./images/Community/2023_Cornell_1.jpeg";
const aboutme_2="./images/aboutme_2.png";
const aboutme_3="./images/aboutme_3.png";
const aboutme_4="./images/aboutme_4.png";

const McKinsey = [
  "Lead designer for visualizing prompt responses, surfacing data sources, technical tooling, and building safeguards for McKinsey’s RAG-based Generative-AI enterprise platform, with consistent 80% user satisfaction and adoption reported by 40,000 consultants.",
  "Crafted 50+ components for McKinsey’s enterprise and data science design systems, resulting in 1.5M yearly imports, saving ~$12M. Trained and mentored 8 teams to adopt the design system and established scalable component tracking in code and design.",
  "Designed an enterprise SaaS tool end-to-end to evaluate and benchmark operational performance across manufacturing, supply chain, and product development which catalyzed 2 client wins for the SHINGO Prize for operational excellence.",
  "Planned and organized 3 internal hybrid conferences for ~100 designers, user researchers and data scientists across 3 continents and 5 time zones.",
];

const Microsoft = [
  <>Designed key learning experiences and launched <a href="https://www.youtube.com/watch?v=DmmCewXlhv4" rel="noopener noreferrer" target="_blank">Microsoft Community Training</a> – a B2B2C SaaS vocational training and upskilling platform for blue-collar workers with over 9.3M learners with clients such as Government of India, UNICEF etc.</>,
  <>Created an <a href="https://news.microsoft.com/en-in/microsoft-and-apollo-hospitals-introduce-ai-powered-cardiovascular-disease-risk-score/" rel="noopener noreferrer" target="_blank">AI assistant</a> for doctors to assess patients’ risk of heart attacks and early detection of cardiovascular diseases, in partnership with Apollo Hospital – one of India’s biggest hospital chains.</>,
];

//  ______________________________________________________________________________________

export default function AboutMe() {
  return (
    <>
      <SiteHeader />
      <Divider hidden />
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={9} tablet={10} mobile={16}>
              <Header as="h1">About</Header>
              <p style={{ fontSize: "16px" }}>
                I am a Product Designer with an expertise in building design
                systems and enterprise experiences for digital-physical
                interactions. Having shipped products for over 10 million users,
                I embrace a collaborative and cross-disciplinary approach,
                rooted in product thinking principles.
                <br />
                <br />
                I am currently building a RAG-based {" "}
                <a href="https://www.mckinsey.com/about-us/new-at-mckinsey-blog/meet-lilli-our-generative-ai-tool" rel="noopener noreferrer" target="_blank" title="McKinsey's Lilli" style={{color: "inherit"}}>
                <u>GenAI platform</u></a> and design systems at McKinsey. Previously, I was at {" "}
                <a href="/tulip" rel="noopener noreferrer" target="_blank" title="McKinsey's Lilli" style={{color: "inherit"}}>
                <u>Tulip</u></a>, making digital manufacturing 
                ops easy to use, scale, and derive actionable outcomes from. I have published 
                6 research papers in Human-Computer Interaction, and bring a qualitative and 
                quantitative insight driven approach to my work. 
                <br />
                <br />
                Outside of work, I deliver talks on design at universities conferences, mentor on{" "}
                <a href="https://adplist.org/mentors/harshika-jain" rel="noopener noreferrer" target="_blank" title="Harshika's profile on ADPList" style={{color: "inherit"}}>
                  <u>ADPList</u></a>{" "}and contribute on{" "}
                <a href="https://ux.stackexchange.com/users/135510/harshikerfuffle?tab=profile" rel="noopener noreferrer" target="_blank" title="Harshika's profile on Stack Exchange" style={{color: "inherit"}}>
                  <u>StackExchange</u></a>, and
                practice Taekwondo. Let's connect on LinkedIn or via email!
                <br />
                <br />
                {/* stackexchange profile */}
                {/* <a
                  href="https://ux.stackexchange.com/users/135510/harshikerfuffle?tab=profile"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Harshika's profile on Stack Exchange"
                  style={{ marginRight: "1.5%" }}
                >
                  <img
                    src="https://stackexchange.com/users/flair/15757726.png"
                    width="208"
                    height="58"
                    alt="Harshika's profile on Stack Exchange"
                  />
                </a>
                {/* adplist profile */}
                {/* <a
                  href="https://adplist.org/mentors/harshika-jain"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Harshika's profile on ADPList"
                >
                  <img
                    src={ADPList}
                    width="auto"
                    height="58"
                    alt="Harshika on ADPList"
                  />
                </a> */}
              </p>
            </Grid.Column>

            {/* ________________________________________________________________________________ */}

            <Grid.Column width={1} only="computer"/>
            <Grid.Column computer={5} tablet={6} only="computer tablet">
              <Image src={headShot} alt="harshika" centered/>
            </Grid.Column>
            <Grid.Column width={1} only="computer"/>
          </Grid.Row>
          <Divider hidden />
        </Grid>

        <Grid doubling columns={4}>  
          <Grid.Row><Grid.Column><Header as="h1">I 💚 to make things</Header></Grid.Column></Grid.Row>
          <Grid.Row>
          <Grid.Column width ={4} textAlign="center">
                <Image src={aboutme_3} rounded bordered/>
                <p style={{marginTop: "3%"}}>Making in digital and tangible media</p>
            </Grid.Column>

            <Grid.Column width ={4} textAlign="center">
                <Image src={aboutme_4} rounded bordered/>
                <p style={{marginTop: "3%"}}>Developing this website using React</p>
            </Grid.Column>

            <Grid.Column width ={4} textAlign="center">
                <Image src={Cornell_1_2023} rounded  bordered/>
                <p style={{marginTop: "3%"}}>Giving back and &nbsp;
                <a href="/community" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                    <u>building community</u></a></p>
            </Grid.Column>

            <Grid.Column width ={4} textAlign="center">
              <Image src={aboutme_2} rounded bordered/>
              <p style={{marginTop: "3%"}}>Documenting vegetarian food finds on Google Maps</p>
            </Grid.Column>
          </Grid.Row>
          
          {/* ________________________________________________________________________________ */}
          <HorizontalRule size="Large" />
        </Grid>

        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1">Resume</Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column width={9}>
              <>
                <h3>Experience</h3>
                {/* McKinsey */}
                <ResumeContent
                  contentTitle="MCKINSEY, Remote, USA"
                  subTitle="Senior Product Designer | Sept 2021 - Present"
                  ListItems={McKinsey}
                />

                {/* Tulip */}
                <ResumeContent
                  contentTitle="TULIP INTERFACES, Somerville, MA"
                  subTitle="Product Designer 2 | Mar 2020 - Sept 2021"
                  contentItems="Overhauled IIoT machine monitoring and connectivity experiences, solving complex workflows 
                  to improve performance tracking and operational efficiency for frontline operations with 45,000 applications 
                  created by 4000 citizen developers per year. Managed an intern, guiding them through a structured development 
                  plan, and supporting their professional growth. Established the design system as a single source of truth and 
                  designed 30 components, optimizing design and development time. Collaborated with leadership and product team 
                  to align on business-critical objectives, and with external stakeholders to define the product vision."
                />

                {/* Morphing Matter Lab */}
                <ResumeContent
                  contentTitle="MORPHING MATTER LAB, Remote, Voluntary"
                  subTitle="Research Collaborator | Apr 2019 - Dec 2023"
                  contentItems={<>Funded by NSF, designed and fabricated tangible morphing artifacts and created a physical-digital toolkit to engage young adults in
                  STEM disciplines by teaching concepts of computational thinking, material science, and physics through hands-on activities, which
                  resulted in <a href="https://scholar.google.com/citations?user=T7eS7F8AAAAJ&hl=en" rel="noopener noreferrer" target="_blank">5 research papers</a> published in leading HCI conferences such CHI, DIS, SCF, and C&C.</>}
                />

                {/* Autodesk */}
                <ResumeContent
                  contentTitle="AUTODESK, Novi, MI"
                  subTitle="User Experience Design Intern | Jun 2019 - Aug 2019"
                  contentItems="Enhanced file and data management workflows and designed the 3D manipulation interactions of 
                  Fusion 360 on mobile, validating the design process through surveys, stakeholder interviews, prototyping, 
                  and user testing. "
                />

                {/* Microsoft */}
                <ResumeContent
                  contentTitle="MICROSOFT, India"
                  subTitle="User Experience Designer | Dec 2017 - Jun 2018"
                  ListItems={Microsoft}
                />

                {/* GE Healthcare */}
                <ResumeContent
                  contentTitle="GE HEALTHCARE, Bangalore, India"
                  subTitle="Technology Medicine Innovation Intern | Apr 2017 - Oct 2017"
                  contentItems="Collaborated with health care practitioners to operationalize the vision of non-hospital-like 
                  environment for patients undergoing cancer treatment and strengthen GE’s business strategy to offer holistic 
                  solutions to clients beyond their flagship medical devices. "
                />

                {/* Hyperloop India */}
                <ResumeContent
                  contentTitle="HYPERLOOP INDIA, India"
                  subTitle="Design Lead | Feb 2017 - Sep 2017"
                  contentItems="Designed and manufactured India’s 1st magnetic levitation powered Hyperloop pod which competed at the SpaceX Competition
                  Weekend 2. Hired and managed a design team of 6 and led designs for the GUI of the pod’s control panel."
                />

                {/* Godrej */}
                <ResumeContent
                  contentTitle="GODREJ and BOYCE, India"
                  subTitle="Industrial Design Intern | May 2016 - Jul 2016"
                  contentItems="Conceptualized futuristic devices for household cleanliness."
                />

                {/* Mobile Life Centre */}
                <ResumeContent
                  contentTitle="MOBILE LIFE CENTRE, Stockholm, Sweden"
                  subTitle="Research Intern | Sep 2015 - Oct 2015"
                  contentItems="Developed a vibro-tactile aid for the deaf-blind to make them experience time through music."
                />

                {/* Ekam Eco Solutions */}
                <ResumeContent
                  contentTitle="EKAM ECO SOLUTIONS, IIT Delhi, India"
                  subTitle="Industrial Design Intern | May 2015 - Jul 2015"
                  contentItems="Designed and prototyped a minimum water-consuming urinal for women to make urinating in public areas hygienic and hassle-free."
                />
              </>

              {/* ________________________________________________________________________________ */}

              <h3 style={{ marginTop: "5em" }}>Projects</h3>
              {/* TruckLite */}
              <ResumeContent
                contentTitle="CAPSTONE PROJECT with TRUCK-LITE"
                subTitle="Carnegie Mellon University | Jan 2019 - May 2019"
                contentItems="Defined product strategy and designed a mobile application for truck drivers which utilizes trailer sensor information to simplify
                workflows, and reduced safety inspection time by 5 minutes."
              />
              {/* FOOD INSECURITY */}
              <ResumeContent
                contentTitle="FOOD INSECURITY on COLLEGE CAMPUSES"
                subTitle="Carnegie Mellon University | Oct 2018 - Feb 2019"
                contentItems="Proposed service design solution of distribution of campus pantries to each department for helping 19% of students across the USA who are food insecure to Feeding America."
              />
            </Grid.Column>
            {/* ________________________________________________________________________________ */}

            {/* Blank column as spacer */}
            <Grid.Column width={1} />
            {/* ________________________________________________________________________________ */}

            <Grid.Column width={6}>
              <>
                <h3>Education</h3>
                {/* CMU */}
                <ResumeContent
                  contentTitle="CARNEGIE MELLON UNIVERSITY, Pittsburgh, PA"
                  subTitle="MS Engineering Design | Dec 2019 | GPA: 3.95/4.00"
                />
                {/* NID */}
                <ResumeContent
                  contentTitle="NATIONAL INSTITUTE OF DESIGN, Ahmedabad, India"
                  subTitle="Bachelor of Product Design | Dec 2017"
                />
                {/* Konstfack */}
                <ResumeContent
                  contentTitle="KONSTFACK UNIVERSITY, Stockholm, Sweden"
                  subTitle="Industrial Design Exchange | Aug 2015 - Jan 2016"
                />
              </>

              {/* ________________________________________________________________________________ */}
              {/* Skills */}

              <h3 style={{ marginTop: "5em" }}>Skills</h3>
              <h4 style={{ marginTop: "-5px" }}>Design</h4>
              <p style={{ marginTop: "-10px" }}>
                Design Systems, Information Architecture, Product Development,
                Digital and Physical prototyping, User Research, Jobs-to-be-Done
              </p>
              <h4>Software</h4>
              <p style={{ marginTop: "-10px" }}>
                Figma, Sketch, Miro, Photoshop, Illustrator, Rhinoceros 3D, Keyshot, InVision,
                Premiere Pro
              </p>
              <h4>Development</h4>
              <p style={{ marginTop: "-10px" }}>React, HTML, CSS, JS, Python</p>

              {/* ________________________________________________________________________________ */}
              {/* Papers */}

              <h3 style={{ margin: "5em 0px 1.2em" }}>
                Academic Contributions
              </h3>

              {/* Chapter in Book */}
              <ResumeContent
                subTitle={
                  <>
                    <a
                      href="https://www.degruyter.com/document/doi/10.1515/9783035626896/html?lang=en"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Architected Morphing Matter: The Confluence of Geometry and Hidden Forces
                    </a>
                    {" | Book Chapter in Interactive Design Towards a Responsive Environment, 2023"}
                  </>
                }
                contentItems={
                  <>
                  {"Lining Yao, "}
                 <span style={{ fontWeight: "bold" }}>Harshika Jain</span>
                  </>
                }
              />

              {/* MM4G Workshop */}
              <ResumeContent
                subTitle={
                  <>
                    <a
                      href="https://dl.acm.org/doi/10.1145/3527927.3531203"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Designing Morphing Artifacts for Creative STEM
                      Explorations
                    </a>
                    {" | C&C 2022"}
                  </>
                }
                contentItems={
                  <>
                    <span style={{ fontWeight: "bold" }}>Harshika Jain*</span>
                    {", Melinda Chen*, Alisha Collins, Lining Yao (*contributed equally)"}
                  </>
                }
              />

              {/* MM4G Poster */}
              <ResumeContent
                subTitle={
                  <>
                    <a
                      href="https://dl.acm.org/doi/10.1145/3527927.3535211"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Morphing Matter for Girls{" "}
                    </a>
                    {" | C&C 2022"}
                  </>
                }
                contentItems={
                  <>
                    <span style={{ fontWeight: "bold" }}>Harshika Jain</span>
                    {", Alisha Collins, Melinda Chen, Lining Yao"}
                  </>
                }
              />

              {/* MM4G Demo */}
              <ResumeContent
                subTitle={
                  <>
                    <a
                      href="https://dl.acm.org/doi/abs/10.1145/3527927.3535196"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Demonstrating DIY Methods for Actuating Morphing Matter
                    </a>
                    {" | C&C 2022"}
                  </>
                }
                contentItems={
                  <>
                    <span style={{ fontWeight: "bold" }}>Harshika Jain*</span>
                    {
                      ", Melinda Chen*, Sunniva Liu, Riya Bobde, Cindy Liu, Alisha Collins, Lining Yao"
                    }
                  </>
                }
              />

              {/* Ocean Garden */}
              <ResumeContent
                subTitle={
                  <>
                    <a
                      href="https://dl.acm.org/doi/fullHtml/10.1145/3461778.3462136"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Hydrogel-based DIY Underwater Morphing Artifacts
                    </a>
                    {" | DIS 2021"}
                  </>
                }
                contentItems={
                  <>
                    <span style={{ fontWeight: "bold" }}>Harshika Jain*</span>
                    {", Kexin Lu*, Lining Yao"}
                  </>
                }
              />

              {/* Geodesy+ */}
              <ResumeContent
                subTitle={
                  <>
                    <a
                      href="https://www.morphingmatter.cs.cmu.edu/s/Geodesy_SCF.pdf"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Inverse Design Tool for Asymmetrical Self-Rising Surfaces
                      with Color Texture
                    </a>
                    {" | SCF 2020"}
                  </>
                }
                contentItems={
                  <>
                    {"Jianzhe Gu, Vidya Narayanan, Guanyun Wang, Danli Luo, "}
                    <span style={{ fontWeight: "bold" }}>Harshika Jain</span>
                    {
                      ", Kexin Lu, Fang Qin, Sijia Wang, James McCann, Lining Yao"
                    }
                  </>
                }
              />

              {/* Track Me Not */}
              <ResumeContent
                subTitle={
                  <>
                    <a
                      href="https://www.researchgate.net/publication/334465668_Track_Me_Not_Design_for_Trust_Transparency_and_Control"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Track Me Not - Design for Trust, Transparency and Control
                    </a>
                    {" | 2017"}
                  </>
                }
                contentItems={
                  <>
                    <span style={{ fontWeight: "bold" }}>Harshika Jain</span>
                    {", Vaibhav Singh"}
                    <br />
                    {"Giga Map published in RSD6 proceedings, Oslo."}
                  </>
                }
              />

              {/* Humanure */}
              <ResumeContent
                subTitle={
                  <>
                    <a
                      href="http://www.ipindia.nic.in/writereaddata/Portal/IPOJournal/1_1521_1/Part-2_Designs.pdf"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Device for Making Compost, Design Number 286447, Class
                      09-03
                    </a>
                    {" | 2016"}
                  </>
                }
                contentItems="The device produces manure from human excreta and is designed for off-the-grid areas. Registered under the Designs Act, 2000, Government of India."
              />

              {/* ________________________________________________________________________________ */}

              <h3 style={{ marginTop: "5em" }}>Awards</h3>
              {/* Oh Deer */}
              <ResumeContent
                contentTitle="OH! DEER"
                subTitle="Carnegie Mellon University | Dec 2018 - Aug 2019"
                contentItems="Raised $5000 in research funding to reduce deer-vehicle collisions."
              />
              {/* McGinnis */}
              <ResumeContent
                contentTitle="McGINNIS VENTURE CAPITAL CHALLENGE"
                subTitle="Swartz Centre for Entrepreneurship @CMU | Mar 2019"
                contentItems="Finalist. Pitched 1SpecialPlace, a startup offering online speech therapy to investors over 3 rounds."
              />
              {/* Space Innovation Challenge */}
              <ResumeContent
                contentTitle="SPACE INNOVATION CHALLENGE"
                subTitle="Sponsored by Boeing HorizonX and Blue Origin | Dec 2018"
                contentItems="2nd position. Created a business case for Space Tourism for extending the operation of US assets onboard the ISS at a profit by 2024."
              />
              {/* Honeywell Aerospace */}
              <ResumeContent
                contentTitle="HONEYWELL AEROSPACE DESIGN CHALLENGE"
                subTitle="Bangalore, India | Mar 2017"
                contentItems="2nd position. Enhancing Situational Awareness for pilots and air traffic controllers."
              />
              {/* Ratan Tata Scholarship */}
              <ResumeContent
                contentTitle="SIR RATAN TATA TRUST SCHOLARSHIP"
                subTitle="National Institute of Design | 2013"
                contentItems="Secured the highest GPA in Year 1, NID."
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      {/* ________________________________________________________________________________ */}
      <SiteFooter />
    </>
  );
}
