import React, { useState } from "react";
import { Header, Icon } from "semantic-ui-react";

export type Props = {
  children: any;
  moreTitle: string;
  lessTitle: string;
};

export default function ReadMoreLink({
  children,
  moreTitle,
  lessTitle,
}: Props) {
  const [readMore, setReadMore] = useState(false);
  const [hover, setHover] = useState(false);

  function onMouseLeave(e: React.ChangeEvent<any>) {
    setHover(false);
    // e.target.parentElement.style.color = "#2878c2";
  }

  function onMouseEnter(e: React.ChangeEvent<any>) {
    setHover(true);
    // e.target.parentElement.style.color = "#000000";
  }

  return (
    <>
      <Header
        as="h5"
        style={{ color: hover ? "#164d81" : "#000000", cursor: "pointer" }}
        onClick={() => setReadMore(!readMore)}
        onMouseOver={onMouseEnter}
        onMouseOut={onMouseLeave}
      >
        {readMore ? lessTitle : moreTitle}
        <Icon name={readMore ? "caret up" : "caret down"} />
      </Header>

      <div style={{ marginTop: "-0.5em" }}>{readMore && children}</div>
    </>
  );
}
