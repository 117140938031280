import { useRef } from "react";

import {
  Container,
  Button,
  Embed,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";

/* cSpell:disable */

import LinkButton from "../../components/LinkButton";
import HorizontalRule from "../../components/HorizontalRule";
import ContentHeader from "../../components/ContentHeader";
import SiteFooter from "../../components/SiteFooter";

const ALine = "images/MorphingMatter/A-line.jpeg";
const GeodesyPlus = "images/MorphingMatter/Geodesy.jpeg";
const videoPlaceholder = "images/MorphingMatter/videoPlaceholder.jpeg";
const MM4G_1 = "images/MorphingMatter/MM4G_1.jpeg";
const MM4G_2 = "images/MorphingMatter/MM4G_2.jpeg";

export default function MorphingMatter() {
  const backRef = useRef(null);

  return (
    <>
    <Container>
      <Grid stackable>
        <Grid.Row centered>
          <div ref={backRef}>
            <Grid.Column textAlign="center">
              <Header as="h2">
                Hydrogel-Based DIY Underwater Morphing Artifacts
              </Header>
            </Grid.Column>
          </div>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <img
              src="images/MorphingMatter/branches.gif"
              alt="gif branches transformation"
              style={{borderRadius: "4px"}}
            />
          </Grid.Column>
          <Grid.Column>
            <img
              src="images/MorphingMatter/kirigami.gif"
              alt="gif of kirigami transformation"
              style={{ margin: "0px 0px 0px -5%", borderRadius: "4px" }}
            />
          </Grid.Column>
          <Grid.Column>
            <img
              src="images/MorphingMatter/kirigami2.gif"
              alt="gif of kirigami transformation"
              style={{ margin: "0px 0px 0px -10%", borderRadius: "4px"}}
            />
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}
        {/* Intro and Role */}

        <Grid.Row style={{ margin: "2% 0px 0px" }}>
          <Grid.Column width={11}>
            <Header as="h3" style={{ marginRight: "30%" }}>
              A morphing and fabrication technique to democratize the creation
              of controllable morphing 3D underwater structures.
            </Header>
            <p style={{ marginBottom: "3%" }}>
              Hydrogels are versatile morphing materials that have recently been
              adopted for creating shape changing interfaces. However, most
              shape-changing interfaces require advanced material synthesis,
              specialized lab setting for fabrication, and technical knowledge
              is needed to simulate their morphing behavior.
            </p>
            <p style={{ marginBottom: "3%" }}>
              To replicate such structures, these factors become a barrier for
              makers. Therefore, to democratize the creation of hydrogel-based
              morphing artifacts and to extend their design space in HCI,{" "}
              <b>
                we propose a water-triggered morphing mechanism which utilizes
                the distance between adjacent hydrogel beads adhered on a thin
                substrate to control their bending angle.
              </b>
            </p>
            <Button
              icon="eye"
              label={{
                as: "a",
                basic: true,
                content: "Read the Paper",
              }}
              labelPosition="right"
              onClick={() => {
                window.open(
                  "https://dl.acm.org/doi/fullHtml/10.1145/3461778.3462136",
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            />
          </Grid.Column>

          <Grid.Column width={1} />

          <Grid.Column width={4}>
            <Header as="h4">My Role</Header>
            <p style={{ margin: "-3% 0px 3%" }}>
              Computational and Digital Fabrication Researcher at Morphing
              Matter Lab, CMU
            </p>

            <Header as="h4">Team</Header>
            <p style={{ margin: "-3% 0px 3%" }}>Kexin Lu, Dr. Lining Yao</p>

            <Header as="h4">NSF Grant</Header>
            <p style={{ margin: "-3% 0px 0px" }}>
              Partially supported by the National Science Foundation grant
              IIS-2017008
            </p>
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Medium" />
        {/* ________________________________________________________________________________ */}

        <Grid.Row>
          <Grid.Column width={6} style={{ marginTop: "1.5em" }}>
            <Header as="h3">Research Contributions</Header>
            <p>
              <b>
                Quantification of the distance between hydrogel beads on a
                substrate
              </b>{" "}
              to control the bending angle of the morphing structures.
              <br />
              <br />
              <b>A computational platform and simulator</b> for creating and
              simulating the morphing behavior of geometries such as kirigami
              and branch-based structures.
              <br />
              <br />
              <b>
                An easy fabrication process for creating tailored morphing
                artifacts
              </b>{" "}
              which enables a large audience to create them.
            </p>
          </Grid.Column>
          <Grid.Column width={10} style={{ gutterWidth: 0 }}>
            <Embed
              active
              id="vGN9RPDUBDY"
              source="youtube"
              placeholder={videoPlaceholder}
              style={{ borderRadius: "0.5em" }}
              autoplay={false}
            />
          </Grid.Column>
        </Grid.Row>
        <HorizontalRule size="Medium" />

        {/* ________________________________________________________________________________ */}
        {/* User study */}
        <Grid.Row columns={3}>
          <Grid.Column>
            <ContentHeader
              titleh3="Morphing Matter for Girls - MM4G"
              subtitle1="Taking our fabrication process into the hands of users"
              subtitle2="Supported by the National Science Foundation"
            />
          </Grid.Column>
          <Grid.Column>
            <Image src={MM4G_1} />
          </Grid.Column>
          <Grid.Column>
            <Image src={MM4G_2} />
          </Grid.Column>
        </Grid.Row>

        <HorizontalRule size="Large" />
        {/* ________________________________________________________________________________ */}
        {/* Other Work done at MML */}

        <Grid.Row style={{ marginBottom: "1%" }}>
          <Grid.Column textAlign="center">
            <Header as="h2">My featured work from Morphing Matter Lab</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "4%" }}>
          <Grid.Column>
            <Segment style={{ marginBottom: "4%" }}>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <Embed
                      id="479200019"
                      placeholder={GeodesyPlus}
                      source="vimeo"
                      style={{ borderRadius: "0.3em" }}
                    />
                  </Grid.Column>
                  <Grid.Column width={9}>
                    <Header as="h3" style={{ marginTop: "3%" }}>
                      Geodesy+
                    </Header>
                    <p style={{ margin: "0px 2% 4% 0px" }}>
                      Geodesy+ is an inverse design tool for creating
                      Asymmetrical Self-Rising Surfaces with color textures. The
                      tool broadens the capabilities of 4D printing with an
                      expanded shape space, a low-cost but precise coloring
                      technique, and an intuitive design process.{" "}
                      <b>
                        I assisted 2 PhD students to ideate multiple artifacts
                        and screen printed them to expand the shape space of the
                        tool, becoming a co-author of the paper.
                      </b>{" "}
                      The paper was accepted at SCF 2020.
                    </p>
                    <div>
                      <LinkButton
                        link="https://www.morphingmatter.cs.cmu.edu/projects/geodesy"
                        text="More Info"
                      />
                      <LinkButton
                        link="https://static1.squarespace.com/static/5fc1dbf8116eb00e3c52b568/t/5fc2fced9d79364840f0b317/1606614312808/Geodesy_SCF.pdf"
                        text="Publication"
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>

            <Segment>
              <Grid stackable>
                <Grid.Row stackable>
                  <Grid.Column width={7}>
                    <Embed
                      id="316299108"
                      placeholder={ALine}
                      source="vimeo"
                      style={{ borderRadius: "0.3em" }}
                    />
                  </Grid.Column>
                  <Grid.Column width={9}>
                    <Header as="h3" style={{ marginTop: "3%" }}>
                      A-Line
                    </Header>
                    <p style={{ margin: "0px 2% 4% 0px" }}>
                      A-line is a 4D printing system for designing and
                      fabricating self-folding three-dimensional shapes out of
                      simple linear elements. Post A-Line was published in CHI
                      2019,{" "}
                      <b>
                        I designed and prototyped a commercially-viable,
                        flat-packing stool for kids using the technology and
                        method invented by the authors.
                      </b>
                    </p>
                    <LinkButton
                      link="https://www.morphingmatter.cs.cmu.edu/projects/a-line"
                      text="More Info"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        {/* ________________________________________________________________________________ */}

        <Grid.Row centered style={{ margin: "8% 0px" }}>
          <Grid.Column verticalAlign="middle">
            <Button
              onClick={() => {
                backRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "start",
                });
              }}
            >
              Back to Top
              <Icon
                name="caret up"
                alt="caret up"
                style={{ marginLeft: "0.5em", marginRight: "-0.5em" }}
              />
            </Button>
          </Grid.Column>
        </Grid.Row>
        {/* ________________________________________________________________________________ */}
      </Grid>
    </Container>
    <SiteFooter />
    </>
  );
}
